import { RouteProps } from 'react-router-dom'
import About from '../pages/about'
import Home from '../pages/home'
import LifeStyle from '../pages/lifestyle'
import Thoughts from '../pages/thoughts'
import NotFoundPage from '../pages/error'
import Contact from '../pages/contact'
import Post from '../pages/post'
import AuthorProfile from '../pages/author'
import Subcategory from '../pages/subcategory'
import Projects from '../pages/projects/ProjectGallery'
import ProjectProfile from '../pages/projects'
import { MacrosCalculator } from '../pages/tools/MacrosCalculator'
import { PATH } from '../constants/PathConstants'
import NutritionalValue from '../pages/tools/NutritionalValue'
import AddPost from '../pages/addPost'

const layoutRoutes: RouteProps[] = [
  {
    path: PATH.HOME,
    element: <Home />,
  },
  {
    path: PATH.ABOUT,
    element: <About />,
  },
  {
    path: PATH.PROJECTS,
    element: <Projects />,
  },
  {
    path: PATH.PROJECT,
    element: <ProjectProfile />,
  },
  {
    path: PATH.LIFESTYLE,
    element: <LifeStyle />,
  },
  {
    path: PATH.THOUGHTS,
    element: <Thoughts />,
  },
  {
    path: PATH.CONTACT,
    element: <Contact />,
  },
  {
    path: PATH.POST,
    element: <Post />,
  },
  {
    path: PATH.SUBCATEGORY,
    element: <Subcategory />,
  },
  {
    path: PATH.AUTHOR,
    element: <AuthorProfile />,
  },
  {
    path: PATH.TOOLS.MACROS_CALCULATOR,
    element: <MacrosCalculator />,
  },
  {
    path: PATH.TOOLS.NUTRITIONAL_VALUE,
    element: <NutritionalValue />,
  },
  {
    path: PATH.ADD_POST,
    element: <AddPost />,
  },
  {
    path: PATH.PAGE_NOT_FOUND,
    element: <NotFoundPage />,
  },
]
export default layoutRoutes

import { ReactNode, useEffect, useState } from 'react'
import { createContext } from 'react'

interface IDarkModeContext {
  isDarkMode: boolean
  setDarkMode: (darkMode: boolean) => void
}
const initialState: IDarkModeContext = {
  isDarkMode: true,
  setDarkMode: () => undefined,
}

export const DarkModeContext = createContext<IDarkModeContext>(initialState)

export const DarkModeProvider = ({ children }: { children: ReactNode }) => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(true)

  useEffect(() => {
    const persistedDarkModeString = localStorage.getItem('darkMode')
    const persistedDarkMode = Boolean(persistedDarkModeString || 'true')
    setIsDarkMode(persistedDarkMode)
  }, [])

  const setDarkMode = (darkMode: boolean) => {
    localStorage.setItem('darkMode', String(darkMode))
    setIsDarkMode(darkMode)
  }

  return (
    <DarkModeContext.Provider value={{ isDarkMode, setDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  )
}

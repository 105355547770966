import { useFoodsApi } from './useFoodsApi'
import { BrandedFoodItem, SearchResult } from '../api/foods'
import { useState } from 'react'

const useNutritionalTool = () => {
  const foodsApi = useFoodsApi()
  const [isLoading, setIsLoading] = useState(false)

  const handleSearchFood = async (keyword: string) => {
    try {
      const response = await foodsApi.getFoodsSearch(keyword, ['Branded'])
      console.log(response.data)
      const data: SearchResult = response.data as SearchResult
      console.log(data)
      return data.foods
    } catch (e) {
      console.log(e)
    }
  }
  const handleSelectedFood = async (foodId: string) => {
    try {
      const response = await foodsApi.getFood(foodId)
      const data: BrandedFoodItem = response.data as BrandedFoodItem
      console.log(data)
      if (data.labelNutrients && data.foodNutrients) {
        const proteins = data.foodNutrients.find((item) => {
          return item.nutrient && item.nutrient.id === 1003
        })
        const fat = data.foodNutrients.find((item) => {
          return item.nutrient && item.nutrient.id === 1004
        })
        const carbohydrates = data.foodNutrients.find((item) => {
          return item.nutrient && item.nutrient.id === 1005
        })
        return {
          id: data.fdcId,
          description: data.description,
          calories: data.labelNutrients.calories?.value,
          protein: proteins?.amount,
          carbohydrates: carbohydrates?.amount,
          fat: fat?.amount,
          servingSize: data.servingSize,
          servingUnit: data.servingSizeUnit || 'no data',
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  return {
    isLoading,
    setIsLoading,
    handleSearchFood,
    handleSelectedFood,
  }
}
export default useNutritionalTool

import { useState, useEffect } from 'react'

function useDevice() {
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    const updateDeviceType = () => {
      const width = window.innerWidth

      setIsMobile(width <= 768)
      setIsTablet(width > 768 && width <= 1024)
      setIsDesktop(width > 1024)
    }

    updateDeviceType()

    window.addEventListener('resize', updateDeviceType)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateDeviceType)
    }
  }, [])

  return { isMobile, isTablet, isDesktop }
}

export default useDevice

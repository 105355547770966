import {PATH} from "./PathConstants";
import {SEOInformation} from "../components/SEO";

type SEOConstants = { readonly [path: string]: SEOInformation }

/**
 * @const SEOConstants will keep the SEO values for all those static pages
 *        The rest of the pages will have an implementation on their own
 */
export const SEOConstants: SEOConstants = {
    [PATH.HOME]: {
        title: 'leonacostaok | spirituality & lifestyle 1323',
        description: 'A personal journey of self-discovery, healing, and adventure. Explore philosophy, spirituality, and lifestyle through the lens of one individual\'s experiences. Discover insights on body-mind healing, personal growth, and the pursuit of extraordinary life moments.',
        type: 'website',
        url: 'https://leonacosta.com/',
        image: 'images/leonacostaok-preview.png'
    },
    [PATH.TOOLS.MACROS_CALCULATOR]: {
        title: 'Macros Calculator | Tools @ leonacostaok',
        description: 'A macros calculator estimates the daily intake of macronutrients (protein, carbs, fats) needed for weight loss, gain, or maintenance, based on factors like age, weight, height, and activity level.',
        type: 'website',
    }
}

export enum Socials {
  'instagram' = 'instagram',
  'linkedin' = 'linkedin',
  'telegram' = 'telegram',
  'github' = 'github',
  'facebook' = 'facebook',
}

type SocialsMap = {
  readonly [social: string]: string
} & {
  readonly [social in Socials]?: string
}

export interface Author {
  alias: string
  name: string
  slogan: string
  bio: string
  profile_image: string
  join_date: string
  socials: SocialsMap
}

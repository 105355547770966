import { Swiper, SwiperSlide } from 'swiper/react'
import { styled } from 'styled-components'
import {
  black,
  center,
  flex_col,
  grayish,
  graylight,
  grayverylight,
  primary,
  text_base,
  text_sm,
  text_xl,
  white,
} from '../../styles/GlobalStyles'
import { Product } from '../../models/product'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css'

interface CarouselProps {
  data: Map<string, Product>
  slidesPerView?: number
  breakpoints?: {
    [key: number]: {
      slidesPerView: number
      spaceBetween: number
    }
  }
}
const CarouselProducts: React.FC<CarouselProps> = ({
  data,
  slidesPerView,
  breakpoints,
}) => {
  return (
    <SwiperContainer
      slidesPerView={slidesPerView}
      spaceBetween={20}
      breakpoints={breakpoints}
      grabCursor={true}
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      navigation={true}
      pagination={{ clickable: true }}
    >
      {Array.from(data.values()).map((product, index) => (
        <SwiperItem key={index}>
          <ProductCard>
            <ProductImg src={product.image} />
            <ProductName>{product.name}</ProductName>
            <ProductPrice>
              {product.sale ? (
                <>
                  <ProductPriceSale> £ {product.price} </ProductPriceSale> £{' '}
                  {product.sale}
                </>
              ) : (
                `£ ${product.price}`
              )}
            </ProductPrice>
            <ButtonToCart>add to cart</ButtonToCart>
          </ProductCard>
        </SwiperItem>
      ))}
    </SwiperContainer>
  )
}
const SwiperContainer = styled(Swiper)`
  width: 100%;
`
const SwiperItem = styled(SwiperSlide)`
  ${text_sm}
  ${center}
  height: auto;
`
const ProductPrice = styled.span`
  color: ${grayish};
  transition: all 0.5s ease-in-out;
  ${text_base};
`
const ProductName = styled.div`
  color: ${grayish};
  transition: all 0.5s ease-in-out;
  ${text_xl}
`
const ProductCard = styled.div`
  padding: 10px;
  border: 1px solid transparent;
  background-color: ${graylight};
  row-gap: 10px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  user-select: none;
  ${flex_col};
  &:hover {
    border-color: ${primary};
  }
  &:hover ${ProductPrice} {
    color: ${primary};
  }
  &:hover ${ProductName} {
    color: ${primary};
  }
`

const ProductImg = styled.img`
  object-fit: cover;
  height: 200px;
`

const ProductPriceSale = styled.span`
  text-decoration: line-through;
  color: ${grayverylight};
`
const ButtonToCart = styled.div`
  width: 60%;
  color: ${white};
  background-color: ${black};
  padding: 10px;
  transition: all 0.5s ease-in-out;
  &:hover {
    color: ${black};
    background-color: ${graylight};
  }
`
export default CarouselProducts

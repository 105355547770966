import { useEffect, useState } from 'react'
import { SelectedFood } from '../models/food'
import { number } from '../constants/numberRegex'

interface AddFood extends SelectedFood {
  meal: string
}
export interface PlanProps {
  name: string
  date: string
  id: string | null
  data: AddFood[]
}
const useMealPlans = () => {
  const [listFood, setListFood] = useState<AddFood[]>([])
  const [meals, setMeals] = useState<string[]>(['breakfast', 'lunch', 'dinner'])
  const [listPlan, setListPlan] = useState<PlanProps[]>(() => {
    const mealPlans = localStorage.getItem('meal-plans')
    return mealPlans ? JSON.parse(mealPlans) : []
  })

  const addMeal = (meal: string) => {
    setMeals((prev) => [...prev, meal])
  }
  const deleteMeal = (index: number) => {
    const currentMeals = [...meals]
    currentMeals.splice(index, 1)
    setMeals(currentMeals)
  }
  const editMeal = (index: number, value: string) => {
    const currentMeals = [...meals]
    currentMeals[index] = value
    setMeals(currentMeals)
  }
  const addFood = (
    food?: SelectedFood,
    servingSize?: string,
    meal?: string
  ) => {
    if (food && servingSize && number.test(servingSize)) {
      const size = parseFloat(servingSize)
      if (size === food.servingSize) {
        const newFood: AddFood = {
          ...food,
          meal: meal ? meal : 'breakfast',
        }
        setListFood((prev) => [...prev, newFood])
      } else {
        const calories = food.calories ? food.calories : 0
        const protein = food.protein ? food.protein : 0
        const carbohydrates = food.carbohydrates ? food.carbohydrates : 0
        const fat = food.fat ? food.fat : 0
        const servingSize = food.servingSize ? food.servingSize : 0
        const newFood: AddFood = {
          ...food,
          calories: parseFloat(((calories / servingSize) * size).toFixed(2)),
          protein: parseFloat(((protein / servingSize) * size).toFixed(2)),
          carbohydrates: parseFloat(
            ((carbohydrates / servingSize) * size).toFixed(2)
          ),
          fat: parseFloat(((fat / servingSize) * size).toFixed(2)),
          servingSize: size,
          meal: meal ? meal : 'breakfast',
        }
        setListFood((prev) => [...prev, newFood])
      }
    } else {
      alert('No food selected or invalid serving size value')
    }
  }
  const deleteFood = (index: number) => {
    const currentPlans = [...listFood]
    currentPlans.splice(index, 1)
    setListFood(currentPlans)
  }
  function getCurrentDateTime() {
    const now = new Date()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const year = now.getFullYear()
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const seconds = String(now.getSeconds()).padStart(2, '0')
    const date = `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`
    const id = `${month}${day}${year}${hours}${minutes}${seconds}`
    return { date, id }
  }
  useEffect(() => {
    localStorage.setItem('meal-plans', JSON.stringify(listPlan))
  }, [listPlan])
  const saveMealPlans = (name: string, selectedPlan?: PlanProps | null) => {
    const { date, id } = getCurrentDateTime()
    console.log(selectedPlan)

    if (!selectedPlan) {
      console.log('new plan')
      const plans = { name: name, date: date, id: id, data: listFood }
      setListPlan((prev) => [...prev, plans])
      setListFood([])
    } else {
      const indexSelected = listPlan.findIndex(
        (plan) => plan.id === selectedPlan.id
      )
      const currentPlans = [...listPlan]
      currentPlans[indexSelected] = {
        ...currentPlans[indexSelected],
        name: name,
        data: listFood,
      }
      setListPlan(currentPlans)
      setListFood([])
    }

    alert('save successfully')
  }
  const deleteSavedPlan = (index: number) => {
    const currentPlans = [...listPlan]
    currentPlans.splice(index, 1)
    setListPlan(currentPlans)
  }
  return {
    listFood,
    setListFood,
    addFood,
    saveMealPlans,
    deleteFood,
    meals,
    setMeals,
    addMeal,
    deleteMeal,
    editMeal,
    getCurrentDateTime,
    listPlan,
    deleteSavedPlan,
  }
}
export default useMealPlans

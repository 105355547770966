import { ReactNode } from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'
import { ToastContainer } from 'react-toastify'
import ButtonToTop from '../ButtonToTop/ButtonToTop'
import {useLocation} from "react-router-dom";
import {SEOConstants} from "../../constants/SEOConstants";
import SEO from "../SEO";
import {styled} from "styled-components";

interface LayoutProps {
  children: ReactNode
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
    const {pathname}= useLocation()
    const parsedPathName = pathname.substring(1, pathname.length)
    const hasSeoConstants = parsedPathName in Object.keys(SEOConstants) || parsedPathName === ''
  return (
    <LayourContainer>
        {hasSeoConstants && <SEO information={SEOConstants[parsedPathName]} />}
      <ToastContainer />
      <Navbar />
      {children}
      <Footer />
      <ButtonToTop />
    </LayourContainer>
  )
}
export default Layout

const LayourContainer = styled.main`
  overflow: hidden;
`

import { FormEvent, useEffect, useRef, useState } from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa'
import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe'
import styled from 'styled-components'
import { Button, center, graylight, white } from '../../styles/GlobalStyles'
import { toast } from 'react-toastify'
import { RotatingLines } from 'react-loader-spinner'
import ReCAPTCHA from 'react-google-recaptcha'
import { isValidEmail } from '../../hooks/useIsValidEmail'
import { t } from 'i18next'

const MailchimpForm: React.FC = () => {
  const postUrl = `https://app.us13.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`
  return (
    <MailchimpSubscribe
      url={postUrl}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={(formData) => subscribe(formData)}
        />
      )}
    />
  )
}
export default MailchimpForm

interface CustomFormProps {
  status: string | null
  message: string | Error | null
  onValidated: (data: EmailFormFields) => void
}
const CustomForm: React.FC<CustomFormProps> = ({
  status,
  message,
  onValidated,
}) => {
  const toastSub = 'toast-subscribe'
  const [email, setEmail] = useState('')
  const [subStatus, setSubStatus] = useState<string | null>()
  const [messageIn, setMessage] = useState<string | Error | null>()
  const [loading, setLoading] = useState(false)
  const [showCaptcha, setShowCaptcha] = useState('hidden')
  const captchaRef = useRef<ReCAPTCHA>(null)

  useEffect(() => {
    setSubStatus(status)
    setMessage(message)
  }, [status, message])
  useEffect(() => {
    if (subStatus === 'sending') {
      setLoading(true)
      toast.info('Sending', {
        toastId: toastSub,
        autoClose: false,
      })
    }
    if (subStatus === 'success') {
      setLoading(false)
      toast.update(toastSub, {
        render: 'Thanks for subscribing',
        type: 'success',
        autoClose: 3000,
      })
    }
    if (subStatus === 'error') {
      setLoading(false)
      toast.update(toastSub, {
        render: `${messageIn}`,
        type: 'error',
        autoClose: 3000,
      })
    }
  }, [subStatus, messageIn])
  const toggleRecaptcha = () => {
    setShowCaptcha(showCaptcha === 'hidden' ? 'visible' : 'hidden')
  }
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!email || !isValidEmail(email)) {
      toast.error('Invalid email format', { autoClose: 3000 })
      return
    }
    if (showCaptcha === 'hidden' && !captchaRef.current?.getValue()) {
      toggleRecaptcha()
      return
    }
    if (!captchaRef.current?.getValue()) {
      toast.error('Please Submit Captcha', { autoClose: 3000 })
      return
    }
    onValidated({ EMAIL: email })
    setEmail('')
    captchaRef.current.reset()
  }
  return (
    <>
      <EmailSubContainer onSubmit={(e) => handleSubmit(e)}>
        <EmailInput
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          placeholder={t('common.placeholder.email')}
        ></EmailInput>
        <SubmitButton type="submit" disabled={loading}>
          {loading ? (
            <RotatingLines
              visible={true}
              width="20"
              strokeColor="rgba(255, 255, 255, 0.5)"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
            />
          ) : (
            <SendArrow />
          )}
        </SubmitButton>
      </EmailSubContainer>
      <CaptchaContainer className={`${showCaptcha}`}>
        <ReCAPTCHA
          theme="dark"
          ref={captchaRef}
          sitekey={`${process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}`}
          onChange={(token) => {
            if (token) {
              toggleRecaptcha()
            }
          }}
        />
      </CaptchaContainer>
    </>
  )
}

const SubmitButton = styled(Button)`
  position: absolute;
  height: 100%;
  width: 40px;
  padding: 0;
  top: 0;
  right: 0;
  color: ${graylight};
  ${center}
`
const EmailSubContainer = styled.form`
  position: relative;
  box-sizing: border-box;
`
const SendArrow = styled(FaLongArrowAltRight)`
  color: ${graylight};
`
const EmailInput = styled.input`
  width: 100%;
  position: relative;
  padding: 16px 20px;
  caret-color: white;
  color: ${graylight};
  background: rgba(31, 31, 31, 1);
  border: none;
  border-bottom: 1px solid transparent;
  &:focus {
    color: ${white};
    background: none;
    outline: none;
    border-color: ${graylight};
  }
`
const CaptchaContainer = styled.div`
  position: absolute;
  top: 100%;
  transition: all 0.5s;
  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
  &.visible {
    visibility: visible;
    opacity: 1;
  }
`

import { Dispatch, RefObject, SetStateAction } from 'react'
import { toast } from 'react-toastify'
import emailjs from '@emailjs/browser'
import { isValidEmail } from './useIsValidEmail'

const handleSubmit = async (
  emailRef: RefObject<HTMLInputElement>,
  nameRef: RefObject<HTMLInputElement>,
  messageRef: RefObject<HTMLTextAreaElement>,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  const toastId = 'toast-email'
  const serviceId = 'leonacostaok-blog'
  const templateId = 'contact-email'
  try {
    setLoading(true)
    toast.info('Sending email...', {
      toastId: toastId,
      autoClose: false,
    })

    if (
      nameRef.current &&
      emailRef.current &&
      messageRef.current &&
      nameRef.current.value.trim() !== '' &&
      emailRef.current.value.trim() !== '' &&
      messageRef.current.value.trim() !== ''
    ) {
      if (!isValidEmail(emailRef.current.value)) {
        return toast.update(toastId, {
          render: 'Invalid email format.',
          type: 'error',
          autoClose: 3000,
        })
      }
      await emailjs.send(serviceId, templateId, {
        name: nameRef.current.value,
        email: emailRef.current.value,
        message: messageRef.current.value,
      })
      nameRef.current.value = ''
      emailRef.current.value = ''
      messageRef.current.value = ''
      toast.update(toastId, {
        render: 'Email successfully sent.',
        type: 'success',
        autoClose: 3000,
      })
    } else {
      return toast.update(toastId, {
        render: 'Please fill all the fields',
        type: 'error',
        autoClose: 3000,
      })
    }
  } catch (error) {
    console.log(error)
    toast.update(toastId, {
      render: 'Failed to send email. Please try again.',
      type: 'error',
      autoClose: 3000,
    })
  } finally {
    setLoading(false)
  }
}
export default handleSubmit

import { t } from 'i18next'

interface TextProps {
  as?: keyof JSX.IntrinsicElements
  children: string
  className?: string
}
const Text: React.FC<TextProps> = ({ as = 'span', children, className }) => {
  const Type = as as keyof JSX.IntrinsicElements

  return <Type className={className}>{t(children)}</Type>
}

export default Text

import { Helmet } from 'react-helmet-async'
export interface SEOInformation {
  title?: string
  description?: string
    type?: string
    author?: string
    keywords?: string
    image?: string
    imageAlt?: string
    url?: string
}

interface SEOProps {
    information: SEOInformation
}
const SEO: React.FC<SEOProps> = ({information}) => {
    if(!information) return <></>
    const {
        title,
        description,
        type ,
        author,
        // keywords,
        // image,
        // imageAlt,
        // url
    } = information
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="title" content={title} />
      <meta name="keywords" content="keywords" />
        {author && <meta name="author" content={author} />}
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta
        property="og:title"
        content="I am a short title, including website name, and page title"
      />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="linktoimage.png" />
      <meta property="og:url" content="https://leonacosta.com/robert" />
      <meta property="og:description" content="description" />
      <meta property="og:site_name" content="" />

      {/* End Facebook tags */}
      {/* Twitter tags */}
        {author && <meta name="twitter:creator" content={author} />}
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content="image.png" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image:alt" content="Alt text for image" />
      <meta property="twitter:url" content="https://leonacosta.com/page" />
      <meta name="twitter:site" content="https://leonacosta.com" />
      {/* End Twitter tags */}
      {/* Start dynamic content created tags */}
      <meta
        name="dc.creator"
        content="An entity primarily responsible for making the resource"
      />
      <meta name="dc.title" content="A name given to the resource" />
      <meta name="dc.description" content="An account of the resource" />
      {/* End dynamic content created tags */}
    </Helmet>
  )
}
export default SEO

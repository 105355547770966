import 'leaflet/dist/leaflet.css'

import {
  LayerGroup,
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
} from 'react-leaflet'
import { LatLng, icon, LatLngBounds } from 'leaflet'
import { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { styled } from 'styled-components'
import { RotatingLines } from 'react-loader-spinner'
import Text from '../Text'
import { FaArrowsAltV, FaMountain, FaRuler } from 'react-icons/fa'
import { IconType } from 'react-icons'
import { Track } from 'gpxparser'

const marker = icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: 'https://unpkg.com/leaflet@1.7/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7/dist/images/marker-shadow.png',
})

export interface MapOptions {
  showInformationWidget: boolean
  height: string
  name: string
}

export const defaultMapOptions: MapOptions = {
  showInformationWidget: false,
  height: '400px',
  name: 'map.gpx',
}

interface MapProps {
  slug: string
  options?: MapOptions
}
export const Map = ({ slug, options }: MapProps) => {
  const parsedOptions = { ...defaultMapOptions, ...options }
  const [tracks, setTracks] = useState<Track[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    axios
      .get<Track[]>(
        `${window.location.origin}/assets/posts/${slug}/maps/${parsedOptions.name}-tracks.json`,
        { baseURL: '' }
      )
      .then((response) => {
        setTracks(response.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const position = useMemo(() => {
    if (!tracks || tracks.length === 0) return null
    let sumLat = 0
    let sumLong = 0
    let count = 0
    const { points, distance, elevation } = tracks[0]
    const southWestBound = [1000, 1000]
    const northEastBound = [-1000, -1000]
    // const speed = new Array<number>(points.length)
    // If 1st value = positive the mean you are in East
    // If 1st value = negative the mean you are in West
    // If 2nd value = positive the mean you are in North
    // If 2nd value = negative the mean you are in South
    const all = points.map((p) => {
      sumLat += p.lat
      sumLong += p.lon
      count++
      if (southWestBound[0] > p.lat) southWestBound[0] = p.lat
      if (southWestBound[1] > p.lon) southWestBound[1] = p.lon
      if (northEastBound[0] < p.lat) northEastBound[0] = p.lat
      if (northEastBound[1] < p.lon) northEastBound[1] = p.lon
      // if (ix !== 0 && p.time) {
      //     const previousPoint = points[ix - 1]
      //     if (previousPoint.time)
      //         speed[ix] = (1000 * (distance.cumul[ix] - distance.cumul[ix-1])) / (new Date(p.time).getTime() - new Date(previousPoint.time).getTime())
      //     else
      //         speed[ix] = 0
      // } else
      //     speed[ix] = 0
      return new LatLng(p.lat, p.lon, p.ele)
    })
    const initial = new LatLng(points[0].lat, tracks[0].points[0].lon)
    const center = new LatLng(sumLat / count, sumLong / count)
    const last = new LatLng(
      points[points.length - 1].lat,
      points[points.length - 1].lon
    )
    return {
      all,
      initial,
      center,
      last,
      distance: {
        total: distance.total, // in meters
      },
      bounds: {
        southWest: new LatLng(southWestBound[0], southWestBound[1]),
        northEast: new LatLng(northEastBound[0], northEastBound[1]),
      },
      elevation,
    }
  }, [tracks])

  return isLoading ? (
    <LoadingPlaceholder height={parsedOptions.height}>
      <RotatingLines
        visible={true}
        width={`calc(${parsedOptions.height} * 0.5)`}
        strokeColor="rgba(255, 255, 255, 0.5)"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
      />
    </LoadingPlaceholder>
  ) : position && tracks.length > 0 && tracks[0].points.length > 0 ? (
    <>
      <MapContainer
        center={position.center}
        bounds={
          new LatLngBounds(position.bounds.southWest, position.bounds.northEast)
        }
        maxBounds={
          new LatLngBounds(position.bounds.southWest, position.bounds.northEast)
        }
        scrollWheelZoom={true}
        style={{ height: parsedOptions.height }}
      >
        <LayerGroup>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position.initial} icon={marker}>
            <Popup>Start point</Popup>
          </Marker>
          <Marker position={position.last} icon={marker}>
            <Popup>End point</Popup>
          </Marker>
          <Polyline
            pathOptions={{ fillColor: 'red', color: 'blue' }}
            positions={position.all}
          />
        </LayerGroup>
      </MapContainer>
      {parsedOptions.showInformationWidget && (
        <InformationWidget>
          {buildInformationWidget(
            FaRuler,
            `${(position.distance.total / 1000).toFixed(2)} kms`
          )}
          {buildInformationWidget(
            FaMountain,
            `max ${position.elevation.max.toFixed(2)} mts, min ${position.elevation.min.toFixed(2)} mts`
          )}
          {buildInformationWidget(
            FaArrowsAltV,
            `+${position.elevation.pos.toFixed(2)} mts, -${position.elevation.neg.toFixed(2)} mts`
          )}
        </InformationWidget>
      )}
    </>
  ) : (
    <ErrorSection height={parsedOptions.height}>
      <ErrorMessage>map.error</ErrorMessage>
    </ErrorSection>
  )
}

const buildInformationWidget = (iconType: IconType, value: string) => {
  return (
    <InformationRecord>
      {iconType({ size: '20px' })}
      <InformationValue>{value}</InformationValue>
    </InformationRecord>
  )
}

const InformationWidget = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #1a1a1a;
`

const InformationRecord = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const InformationValue = styled(Text)`
  font-weight: 500;
  font-size: 20px;
`

const LoadingPlaceholder = styled.div<{ height: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height};
  width: 100%;
  background-color: #262626;
`

const ErrorSection = styled.div<{ height: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height};
  width: 100%;
  background-color: #262626;
`

const ErrorMessage = styled(Text)`
  color: red;
`

import {defaultMapOptions, MapOptions} from "../components/Map";

export const parseMapOptions = (options: string) => {
    const optionsList = options.split('\n')
    const finalOptions: MapOptions = defaultMapOptions
    for (let i = 0; i < optionsList.length; i++) {
        const parseOption = optionsList[i].split('=')
        switch (parseOption[0]) {
            case 'showInformationWidget':
                finalOptions[parseOption[0]] = Boolean(parseOption[1])
                break
            case 'height': case 'name':
                finalOptions[parseOption[0]] = parseOption[1]
                break
            default:
                console.warn(`${parseOption[0]} is not a valid key`)
        }
    }
    return finalOptions
}

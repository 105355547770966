import { Routes, Route, useLocation } from 'react-router-dom'
import layoutRoutes from '../config/layoutRoutes'
import { AnimatePresence } from 'framer-motion'
import { Suspense, useEffect } from 'react'

export default function AppRoutes() {
  const location = useLocation()
  //scroll to top when change route

  return (
    <AnimatePresence>
      <Suspense fallback="loading">
        <ScrollToTop />
        <Routes location={location} key={location.key}>
          {layoutRoutes.map((route) => (
            <Route key={route.path} {...route}></Route>
          ))}
        </Routes>
      </Suspense>
    </AnimatePresence>
  )
}
const ScrollToTop: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return null
}

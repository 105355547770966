import { styled } from 'styled-components'
import {
  Container,
  flex_col,
  grayverylight,
  HeroDescription,
  HeroHeading,
  HeroSection,
  UnderLine,
} from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import Text from '../../components/Text'
import { useArticles } from '../../hooks/useIndexedPosts'
import CarouselPosts from '../../components/Carousel'
import { t } from 'i18next'

const LifeStyle: React.FC = () => {
  const { articles } = useArticles()
  return (
    <Transition>
      <PageContainer>
        <HeroSection>
          <HeroHeading as={'h2'}>{t('lifestyle.heading')}</HeroHeading>
          <HeroDescription>{t('lifestyle.description')}</HeroDescription>
          <UnderLine />
        </HeroSection>
        <SimilarPostsSection>
          <SectionPost>
            <SectionTitle as={'h2'}>subcategory xyz</SectionTitle>
            <CarouselPosts
              data={articles}
              breakpoints={{
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3.5,
                  spaceBetween: 10,
                },
                320: {
                  slidesPerView: 1.5,
                  spaceBetween: 10,
                },
              }}
            ></CarouselPosts>
          </SectionPost>
          <SectionPost>
            <SectionTitle as={'h2'}>subcag keywordrap</SectionTitle>
            <CarouselPosts
              data={articles}
              breakpoints={{
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3.5,
                  spaceBetween: 10,
                },
                320: {
                  slidesPerView: 1.5,
                  spaceBetween: 10,
                },
              }}
            ></CarouselPosts>
          </SectionPost>
        </SimilarPostsSection>
      </PageContainer>
    </Transition>
  )
}
export default LifeStyle

const PageContainer = styled(Container)`
  ${flex_col}
  row-gap: 20px;
`
const SimilarPostsSection = styled.div`
  ${flex_col}
  row-gap: 50px;
`
const SectionPost = styled.div`
  ${flex_col}
  row-gap: 20px;
`
const SectionTitle = styled(Text)`
  color: ${grayverylight};
`

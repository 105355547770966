import {
  Container,
  flex_col,
  graylight,
  grayverylight,
  text_xl,
  white,
} from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import Text from '../../components/Text'
import { styled } from 'styled-components'
import { ButtonArrow } from '../../components/Button'
import { useEffect, useRef, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import handleSubmit from '../../hooks/useSendEmail'
import emailjs from '@emailjs/browser'
import { t } from 'i18next'

const Contact: React.FC = () => {
  useEffect(() => {
    emailjs.init(`${process.env.REACT_APP_EMAILJS_KEY}`)
  }, [])
  const emailRef = useRef<HTMLInputElement>(null)
  const nameRef = useRef<HTMLInputElement>(null)
  const messageRef = useRef<HTMLTextAreaElement>(null)
  const [loading, setLoading] = useState(false)
  return (
    <Transition>
      <ContactContainer>
        <ContactForm>
          <TitleSection>
            <TitleWhite>{'contact.title'}</TitleWhite>
            <TitleGraylight>{'contact.sub-title'}</TitleGraylight>
          </TitleSection>
          <InputSection>
            <NameInput
              ref={nameRef}
              placeholder={t('common.placeholder.name')}
            />
            <EmailInput
              ref={emailRef}
              type="email"
              placeholder={t('common.placeholder.email')}
            />
            <ContentInput
              ref={messageRef}
              placeholder={t('common.placeholder.enquiry')}
              as={'textarea'}
            />
          </InputSection>
          <SubmitContainer>
            <SubmitButton
              onClick={() =>
                handleSubmit(emailRef, nameRef, messageRef, setLoading)
              }
              sendingType={true}
              disabled={loading}
              loading={loading}
            >
              {loading ? (
                <Text>{'common.button.loading'}</Text>
              ) : (
                <Text>{'common.button.send'}</Text>
              )}
            </SubmitButton>
          </SubmitContainer>
        </ContactForm>
      </ContactContainer>
    </Transition>
  )
}
export default Contact

const ContactContainer = styled(Container)`
  padding-top: 150px;
  padding-bottom: 150px;
`
const ContactForm = styled.div`
  width: 60%;
  display: grid;
  grid-template-rows: repeat(3, auto);
  row-gap: 40px;
  @media (max-width: 768px) {
    width: 100%;
  }
`
// const ImageContainer = styled.div`
//     width: 40%;
// `;
const TitleSection = styled.div`
  ${flex_col}
`
const TitleWhite = styled(Text)`
  color: ${white};
  font-size: 30px;
  line-height: 1.5;
`
const TitleGraylight = styled(Text)`
  color: ${graylight};
  ${text_xl}
  line-height: 1.25;
`
const InputSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 10px;
  @media (max-width: 420px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const InputForm = styled.input`
  width: 100%;
  padding: 10px 0;
  outline: none;
  background: transparent;
  border: none;
  caret-color: ${white};
  color: white;
  border-bottom: 1px solid ${grayverylight};
  &::placeholder {
    color: ${grayverylight};
  }
`
const NameInput = styled(InputForm)`
  grid-column: span 1 / span 1;
`
const EmailInput = styled(InputForm)`
  grid-column: span 1 / span 1;
`
const ContentInput = styled(InputForm)`
  grid-column: span 2 / span 2;
  height: 160px;
  resize: none;
  @media (max-width: 420px) {
    grid-column: span 1 / span 1;
  }
`
const SubmitContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`
const SubmitButton = styled(ButtonArrow)`
  grid-column-start: 2;
  &:disabled {
    color: ${grayverylight};
  }
`

import { createContext, ReactNode } from 'react'
import { Project } from '../models/project'
import dataProjects from '../data/projects.json'

export const ProjectContext = createContext<ProjectContextType | undefined>(
  undefined
)
interface ProjectContextType {
  projects: Map<string, Project>
}
interface ProjectProviderProps {
  children: ReactNode
}
export const ProjectProvider: React.FC<ProjectProviderProps> = ({
  children,
}) => {
  const projects: Map<string, Project> = new Map(Object.entries(dataProjects))
  return (
    <ProjectContext.Provider value={{ projects }}>
      {children}
    </ProjectContext.Provider>
  )
}

import { defaultPlayerOptions, PlayerOptions } from '../components/Player'

export const parseSoundOptions = (options: string) => {
  const optionsList = options.split('\n')
  const finalOptions: PlayerOptions = defaultPlayerOptions
  for (let i = 0; i < optionsList.length; i++) {
    const parseOption = optionsList[i].split('=')
    switch (parseOption[0]) {
      case 'src':
      case 'name':
      case 'img':
        finalOptions[parseOption[0]] = parseOption[1]
        break

      default:
        console.warn(`${parseOption[0]} is not a valid key`)
    }
  }
  return finalOptions
}

import { useEffect, useState } from 'react'
import { black, Button, primary, white } from '../../styles/GlobalStyles'
import { FaArrowUp } from 'react-icons/fa'
import { styled } from 'styled-components'

const ButtonToTop = () => {
  const [showButton, setShowButton] = useState(false)
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <BtnToTop onClick={scrollToTop} $show={showButton}>
      <IconArrowUp />
    </BtnToTop>
  )
}
export default ButtonToTop
const BtnToTop = styled(Button)<{ $show?: boolean }>`
  position: fixed;
  bottom: 60px;
  right: 100px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: ${black};
  background: ${primary};
  transition: all 1s;
  border: 1px solid transparent;
  opacity: ${(props) => (props.$show ? '0.7' : '0')};
  visibility: ${(props) => (props.$show ? 'visible' : 'hidden')};
  &:hover {
    opacity: 1;
    color: ${white};
    border-color: ${white};
  }
  @media (max-width: 1024px) {
    right: 20px;
    bottom: 30px;
  }
`
const IconArrowUp = styled(FaArrowUp)`
  font-size: 21px;
`

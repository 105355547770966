import { Fragment } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Text from '../Text'
import { FaGithub, FaTelegram, FaLinkedin, FaInstagram } from 'react-icons/fa'
import {
  center,
  Container,
  flex_col,
  graylight,
  text_2xl,
  text_lg,
  text_sm,
  text_xl,
  text_xs,
} from '../../styles/GlobalStyles'
import MailchimpForm from '../MailchimpForm'
import Logo from '../../assets/svg/logo'
import { t } from 'i18next'

const Footer: React.FC = () => {
  const footerLink = [
    ['home', 'about'],
    ['projects', 'lifestyle'],
    ['thoughts', 'contact'],
  ]

  return (
    <>
      <FooterContainer>
        <ColumnLink>
          <LogoSection>
            <Link to={''}>
              <Logo />
            </Link>
          </LogoSection>
          <LinkSection>
            {footerLink.map((items, index) => (
              <LinkRow key={index}>
                {items.map((item, index1) => (
                  <Fragment key={index1}>
                    <FooterLink to={item === 'home' ? '/' : `${item}`}>
                      <Text>{`footer.navigation.${index1 + index * 2}.label`}</Text>
                    </FooterLink>
                    {index1 === 0 && <>/</>}
                  </Fragment>
                ))}
              </LinkRow>
            ))}
          </LinkSection>
        </ColumnLink>
        <ColumnContact>
          <ContactRow>
            <ContactTitle>{'footer.contact.0.label'}</ContactTitle>
            <ContactText>leon@dandelionlabs.io</ContactText>
          </ContactRow>
          <ContactRow>
            <ContactTitle>{'footer.contact.1.label'}</ContactTitle>
            <ContactText>+84 (0) 343 788-923</ContactText>
          </ContactRow>
          <ContactRow>
            <ContactTitle>{'footer.contact.2.label'}</ContactTitle>
            <SocialsSection>
              <SocialLink to={'/'}>
                <FaInstagram />
              </SocialLink>
              <SocialLink to={'/'}>
                <FaGithub />
              </SocialLink>
              <SocialLink to={'/'}>
                <FaTelegram />
              </SocialLink>
              <SocialLink to={'/'}>
                <FaLinkedin />
              </SocialLink>
            </SocialsSection>
          </ContactRow>
        </ColumnContact>
        <FooterColumnBig>
          <SubTitle as={'h2'}>{t('footer.subscribe.label')}</SubTitle>
          <MailchimpForm />
        </FooterColumnBig>
      </FooterContainer>
      <CopyRightContainer>
        <CopyRightText>{'footer.copyright'}</CopyRightText>
      </CopyRightContainer>
    </>
  )
}
export default Footer

const FooterColumn = styled.div`
  grid-column: span 1 / span 1;
  display: grid;
  @media (max-width: 768px) {
    grid-column: span 2 / span 2;
  }
  @media (max-width: 450px) {
    grid-column: span 4 / span 4;
  }
`
const ColumnLink = styled(FooterColumn)`
  grid-template-rows: repeat(2, 1fr);
  row-gap: 50px;
  @media (max-width: 768px) {
    order: 2;
  }
  @media (max-width: 450px) {
    display: none;
  }
`
const ColumnContact = styled(FooterColumn)`
  grid-template-rows: repeat(3, 1fr);
  row-gap: 20px;
  @media (max-width: 768px) {
    order: 3;
  }
`
const ContactRow = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  row-gap: 8px;
`
const ContactTitle = styled(Text)`
  color: ${graylight};
  ${text_xs}
  @media (max-width: 450px) {
    ${text_xl}
  }
`
const ContactText = styled.span`
  ${text_sm}
  @media (max-width: 450px) {
    ${text_2xl}
  }
`
const SocialsSection = styled(ContactText)`
  ${text_lg}
  display: flex;
  column-gap: 4px;
  cursor: pointer;
`
const SocialLink = styled(Link)``
const FooterColumnBig = styled.div`
  ${flex_col}
  row-gap: 10px;
  grid-column: span 2 / span 2;
  position: relative;
  @media (max-width: 768px) {
    grid-column: span 4 / span 4;
    order: 1;
  }
`
const LogoSection = styled.div`
  ${flex_col}
`
const LinkSection = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
`
const LinkRow = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
`
const FooterLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  ${text_sm}
`
const FooterContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-top: 60px;
  padding-bottom: 60px;
  column-gap: 250px;
  @media (max-width: 1440px) {
    column-gap: 200px;
  }
  @media (max-width: 1280px) {
    column-gap: 100px;
  }
  @media (max-width: 1024px) {
    column-gap: 50px;
  }
  @media (max-width: 768px) {
    column-gap: 25px;
    row-gap: 50px;
  }
`
const SubTitle = styled(Text)`
  font-size: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 1024px) {
    font-size: 32px;
  }
`

const CopyRightContainer = styled(Container)`
  width: 100%;
  ${center}
  padding-top: 60px;
  padding-bottom: 60px;
`
const CopyRightText = styled(Text)`
  color: ${graylight};
`

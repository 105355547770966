import { styled } from 'styled-components'
import {
  Container,
  flex_col,
  graylight,
  grayverylight,
  LeftSection,
  ralewayFont,
  UnderLine,
  white,
} from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import { Link, useParams } from 'react-router-dom'
import Text from '../../components/Text'
import { useEffect, useRef, useState } from 'react'
import { useArticles } from '../../hooks/useIndexedPosts'
import { Article } from '../../models/article'
import { Author } from '../../models/author'
import { useAuthors } from '../../hooks/useIndexedAuthors'
import SEO from '../../components/SEO'
import { MarkdownElement } from '../../components/MarkdownElement'
import { calculateReadingTime } from '../../utils/postUtils'
import { CoolComponent } from '../../components/AnimatedShapes/CoolComponent'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { ButtonArrow } from '../../components/Button'
import { t } from 'i18next'

const Post: React.FC = () => {
  const { slug } = useParams<{ slug: string }>() // get slug through url
  const { articles } = useArticles() // get articles
  const { authors } = useAuthors() // get authors
  const [article, setArticle] = useState<Article | undefined>() // set current post
  const [author, setAuthor] = useState<Author | undefined>() // set current post's author
  const cool_component = useRef<HTMLDivElement>(null) // animation component
  const pageRef = useRef<HTMLDivElement>(null) // page container
  const [extended, setExtended] = useState<boolean>(false) //show extended version
  // Get article and author infomation
  useEffect(() => {
    setArticle(slug ? articles.get(slug) : undefined)
  }, [slug, articles])
  useEffect(() => {
    setAuthor(article ? authors.get(article['author-alias']) : undefined)
  }, [article])
  //handle show extended version
  const toggleExtended = () => {
    setExtended(!extended)
  }
  // scroll animation
  useEffect(() => {
    if (pageRef.current) {
      const { offsetHeight } = pageRef.current
      if (pageRef.current) {
        gsap.to(cool_component.current, {
          scrollTrigger: {
            trigger: pageRef.current,
            start: 'top top',
            end: 'bottom top',
            scrub: 2,
          },
          y: offsetHeight,
          duration: 2,
        })
      }
    }
    return () => gsap.killTweensOf(cool_component.current)
  }, [article])
  gsap.registerPlugin(useGSAP, ScrollTrigger)

  return (
    <>
      {article ? (
        <SEO
          information={{ title: article.title, description: article.content }}
        />
      ) : null}
      <Transition>
        <PageContainer>
          {article ? (
            <LeftSection ref={pageRef}>
              <PostFeaturedImage src={article.featured_image} />
              <PostHeading>
                <PostTitle as={'h2'}>{article.title}</PostTitle>
                <PostCategory>{article.categories.join(', ')}</PostCategory>
                <PostTags>{article.tags.join(', ')}</PostTags>
              </PostHeading>
              <PostAuthor>
                {author ? (
                  <>
                    <LinkAuthor to={`/author/${author.alias}`}>
                      <AuthorImage
                        src={`/assets/${author.profile_image}`}
                        alt="Author profile image"
                      ></AuthorImage>
                    </LinkAuthor>

                    <Link to={`/author/${author.alias}`}>
                      <AuthorName>{author.name}</AuthorName>
                    </Link>
                  </>
                ) : null}
                <PostPublished>{`${calculateReadingTime(article.content)} min read | ${article.publishedDate}`}</PostPublished>
              </PostAuthor>
              {article.extended ? (
                <ExtendedButton sendingType={false} onClick={toggleExtended}>
                  {!extended
                    ? t('post.button.extended')
                    : t('post.button.origin')}
                </ExtendedButton>
              ) : null}

              <UnderLine />
              {extended ? (
                <MarkdownElement article={article} extended={extended} />
              ) : (
                <MarkdownElement article={article} extended={extended} />
              )}
              <UnderLine />
            </LeftSection>
          ) : (
            <Text>{'post.message.not-found'}</Text>
          )}
          <AnimationContainer ref={cool_component}>
            <CoolComponent />
          </AnimationContainer>
        </PageContainer>
      </Transition>
    </>
  )
}
export default Post

const PostFeaturedImage = styled.img`
  height: 300px;
  object-fit: cover;
`
const PageContainer = styled(Container)`
  display: flex;
  @media (max-width: 1100px) {
    position: relative;
  }
`
const PostHeading = styled.div`
  ${flex_col}
`
const PostTitle = styled(Text)`
  color: ${white};
  line-height: 1.4;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 4px;
  margin-bottom: 10px;
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
  }
`
const PostCategory = styled(Text)`
  color: ${graylight};
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: 5px;
  margin-bottom: 10px;
`
const PostTags = styled(Text)`
  color: ${grayverylight};
  line-height: 1.5;
  margin-bottom: 20px;
`
const PostPublished = styled(Text)`
  margin-top: -10px;
  color: ${grayverylight};
`
const PostAuthor = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: auto 1fr;
  column-gap: 20px;
  align-items: center;
  color: ${graylight};
  ${ralewayFont};
`
const ExtendedButton = styled(ButtonArrow)`
  width: 200px;
`
const LinkAuthor = styled(Link)`
  grid-row: span 2 / span 2;
`
const AuthorImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: fill;
  border-radius: 50%;
`
const AuthorName = styled(Text)`
  font-weight: 600;
  font-size: 20px;
`
const AnimationContainer = styled.div`
  width: 40%;
  height: 400px;
  @media (max-width: 1100px) {
    position: absolute;
    right: 0;
    top: 100px;
  }

  // disabled if mobile
  @media (max-width: 800px) {
    display: none;
  }
`

import { useContext } from 'react'
import { ProjectContext } from '../providers/IndexedProjectsProvider'

export const useProjects = () => {
  const context = useContext(ProjectContext)
  if (context === undefined) {
    throw new Error('useProjects must be used within an ProjectsProvider')
  }
  return context
}

import { useEffect, useState } from 'react'

const useDisabledScroll = () => {
  const [isScroll, setIsScroll] = useState(true)
  useEffect(() => {
    if (!isScroll) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [isScroll])

  return { isScroll, setIsScroll }
}

export default useDisabledScroll

import { ThemeProvider } from 'styled-components'
import GlobalStyles from './styles/GlobalStyles'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import Layout from './components/Layout'
import AppRoutes from './routes/AppRoutes'
import './i18n/i18n'
import { useMemo } from 'react'
import { theme } from './styles/Theme'
import { useDarkMode } from './hooks/useDarkMode'
import CookieConsent from 'react-cookie-consent'

const helmetContext = {}
function App() {
  const { isDarkMode } = useDarkMode()
  const themeDarkMode = useMemo(() => theme(true), [])
  const themeLightMode = useMemo(() => theme(false), [])
  return (
    <Router>
      <ThemeProvider theme={isDarkMode ? themeDarkMode : themeLightMode}>
        <GlobalStyles />
        <HelmetProvider context={helmetContext}>
          <Layout>
            <AppRoutes />
          </Layout>
          <CookieConsent
            location="bottom"
            buttonText="accept"
            declineButtonText="decline"
            enableDeclineButton
            cookieName="myAwesomeCookieName2"
            style={{ background: '#2B373B' }}
            buttonStyle={{ background: '#2BC713' }}
            expires={150}
          >
            This website uses cookies to enhance the user experience.{' '}
          </CookieConsent>
        </HelmetProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App

import { FaGithub, FaInstagram, FaLinkedin, FaTelegram } from 'react-icons/fa'
import {
  Container,
  graylight,
  HeadingDetails,
  HeadingSlogan,
  HeadingTitle,
  LeftSection,
  PageBio,
  PageHeading,
} from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import { styled } from 'styled-components'
import { t } from 'i18next'

const About: React.FC = () => {
  const temBio =
    "Leon Acosta is a seasoned entrepreneur and product visionary with a deep-rooted passion for harnessing technology to drive positive social and environmental impact. Leon's entrepreneurial journey is marked by a commitment to people and talent, and he believes in the power of community and education to create lasting change."
  const socials = [
    { icon: FaInstagram, url: 'https://instagram.com/leonacostaok' },
    { icon: FaGithub, url: 'https://github.com/leonacostaok' },
    { icon: FaTelegram, url: 'https://t.me/leonacostaok' },
    { icon: FaLinkedin, url: 'https://linkedin.com/in/leonacostaok' },
  ]
  return (
    <Transition>
      <Container>
        <AboutSection>
          <PageHeading>
            <HeadingDetails>
              <HeadingTitle as={'h2'}>{t('about.heading.title')}</HeadingTitle>
              <HeadingSlogan as={'h5'}>
                {t('about.heading.slogan')}
              </HeadingSlogan>
            </HeadingDetails>
            <PageBio>{temBio}</PageBio>
            <Socials>
              {socials.map((item, index) => (
                <a key={index} href={item.url} target="_blank" rel="noreferrer">
                  <item.icon size={22} />
                </a>
              ))}
            </Socials>
          </PageHeading>
        </AboutSection>
      </Container>
    </Transition>
  )
}
export default About

const Socials = styled.div`
  color: ${graylight};
  display: flex;
  column-gap: 10px;
  margin-top: 10px;
  column-gap: 10px;
  a {
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.2);
      color: white;
    }
  }
`
const AboutSection = styled(LeftSection)`
  row-gap: 30px;
`

import styled from 'styled-components'

export const CoolComponent: React.FC = () => {
  const LAYERS = 20
  const SIZE = 320
  return (
    <ShapeWrapper>
      <ShapeContainer className={'container'}>
        {[...Array(LAYERS)].map((r, ix) =>
          [...Array(LAYERS)].map((r, iz) => (
            <Trigger
              z={iz + 1}
              x={ix + 1}
              className={'trigger'}
              key={`${ix}-${iz}`}
            />
          ))
        )}
        <Monitor className={'monitor'}>
          <div className={'camera o-x'}>
            <div className={'camera o-y'}>
              <div className={'camera o-z'}>
                <VRElement className={'vr'} size={SIZE}>
                  {[...Array(LAYERS)].map((ROBERT, ix) => (
                    <VRLayer
                      className={'vr_layer'}
                      size={SIZE}
                      i={ix + 1}
                      key={ix}
                      layers={LAYERS}
                    >
                      <div className={'vr_layer_item'} />
                    </VRLayer>
                  ))}
                </VRElement>
              </div>
            </div>
          </div>
        </Monitor>
      </ShapeContainer>
    </ShapeWrapper>
  )
}

const ShapeWrapper = styled.div`
  background: transparent;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
`

const ShapeContainer = styled.div`
  position: relative;
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: repeat(20, 1fr);
  grid-template-columns: repeat(20, 1fr);
  transform-style: preserve-3d;

  @keyframes sphere {
    0% {
      transform: scale(0) rotateZ(45deg);
      opacity: 0.2;
    }

    50% {
      transform: scale(0) rotateZ(45deg);
      opacity: 0.4;
    }

    100% {
      transform: scale(1) rotateZ(45deg);
      opacity: 1;
    }
  }

  @keyframes color {
    0% {
      border-color: #57b000;
    }

    50% {
      border-color: rgb(8, 173, 56);
    }

    100% {
      border-color: rgba(57, 255, 0, 0.62);
    }
  }
`

const Trigger = styled.div<{ z: number; x: number }>`
  &:active ~ .monitor {
    .vr_layer {
      border-radius: 100%;
    }
    .vr_layer_item {
      width: 40%;
      height: 40%;
      border-radius: 5px;
    }
  }
  &:hover ~ .monitor {
    .camera {
      &.o-y {
        transform: rotateY(${({ z }) => `${(z - 10) * -8}`}deg);
      }

      &.o-x {
        transform: rotateX(${({ x }) => `${(x - 10) * 8}`}deg);
      }
    }
  }
`

const Monitor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transform-style: preserve-3d;
  .camera {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: 500ms;

    &.o-y {
      transform: rotateY(-30deg);
    }
  }
`

const VRElement = styled.div<{ size: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  transform: translate(-50%, -50%);
  transform-style: preserve-3d;
`

const VRLayer = styled.div<{ size: number; layers: number; i: number }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  //background: rgba(#09f, 0.006);
  border-radius: 20px;
  transform: translateZ(
    ${({ i, size, layers }) => `${(i * size) / layers - size / 2}px`}
  );
  transition: border-radius 1000ms;
  
  .vr_layer_item {
    width: 70%;
    height: 70%;
    border: 1px solid transparent;
    border-radius: 100%;
    background: rgba(#000, 0.05);
    animation:
      sphere 3000ms cubic-bezier(0.215, 0.61, 0.355, 1) alternate infinite,
      color 5000ms linear alternate infinite;
    transition: 500ms;
    animation-delay: ${({ i }) => `${i * 210}ms`};
  }
`

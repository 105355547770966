import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)
interface ChartProps {
  id: string
  values: number[]
  label: string[]
}
const DoughnutChart: React.FC<ChartProps> = ({ id, values, label }) => {
  const options = {
    legend: {
      display: false,
      position: 'right',
    },
    elements: {
      arc: {},
    },
    // plugin: {
    //   tooltip: {
    //     callbacks: {
    //       label: function (context: any) {
    //         console.log(context)
    //         let label = context.dataset.label || ''
    //         if (label) {
    //           label += '%'
    //         }
    //         return label
    //       },
    //     },
    //   },
    // },
  }
  const data = {
    maintainAspectRatio: true,
    responsive: true,
    labels: label,
    datasets: [
      {
        data: values,
        backgroundColor: ['#fae76b', '#ffb578', '#b4ff83'],
      },
    ],
  }

  return (
    <div key={id} style={{ width: '200px' }}>
      <Doughnut data={data} options={options} />
    </div>
  )
}
export default DoughnutChart

export enum MacroNutrients {
    PROTEIN = 'PROTEIN',
    FATS = 'FATS',
    CARBOHYDRATES = 'CARBOHYDRATES',
}

export const getMacroNutrientsHint = (l: MacroNutrients) => {
    switch (l) {
        case MacroNutrients.PROTEIN: return 'Essential for muscle growth and repair.'
        case MacroNutrients.CARBOHYDRATES: return 'Provide energy, especially for intense workouts.'
        case MacroNutrients.FATS: return 'Essential for hormone production and nutrient absorption.'
    }
}

export enum ActivityLevel {
    SEDENTARY = 'SEDENTARY',
    LIGHTLY_ACTIVE = 'LIGHTLY_ACTIVE',
    MODERATELY_ACTIVE = 'MODERATELY_ACTIVE',
    VERY_ACTIVE = 'VERY_ACTIVE',
    EXTREMELY_ACTIVE = 'EXTREMELY_ACTIVE',
}

export const getActivityLevelHint = (l: ActivityLevel) => {
    switch (l) {
        case ActivityLevel.SEDENTARY: return 'Little to no physical activity.'
        case ActivityLevel.LIGHTLY_ACTIVE: return 'Light exercise 1-3 times per week.'
        case ActivityLevel.MODERATELY_ACTIVE: return 'Moderate exercise 3-5 times per week.'
        case ActivityLevel.VERY_ACTIVE: return 'Intense exercise 6-7 times per week.'
        case ActivityLevel.EXTREMELY_ACTIVE: return 'Professional athlete or intense physical labor daily.'
    }
}

export const getActivityLevelMultiplier = (l: ActivityLevel) => {
    switch (l) {
        case ActivityLevel.SEDENTARY:
            return 1
        case ActivityLevel.LIGHTLY_ACTIVE:
            return 1.2
        case ActivityLevel.MODERATELY_ACTIVE:
            return 1.375
        case ActivityLevel.VERY_ACTIVE:
            return 1.55
        case ActivityLevel.EXTREMELY_ACTIVE:
            return 1.725
    }
}

export enum Physique {
    SHREDDED = 'SHREDDED',
    LEAN = 'LEAN',
    NORMAL = 'NORMAL',
    OVERWEIGHT = 'OVERWEIGHT',
    OBESE = 'OBESE',
}

export const getPhysiqueHint = (l: Physique) => {
    switch (l) {
        case Physique.SHREDDED: return {
            definition: 'A physique characterized by minimal body fat and well-defined muscles.',
            appearance: 'Visible muscle definition, lean appearance, and minimal body fat.'
        }
        case Physique.LEAN: return {
            definition: 'A physique with low body fat and visible muscle definition, but not as extreme as "Shredded."',
            appearance: 'Lean appearance, some muscle definition, but not as pronounced as "Shredded."'
        }
        case Physique.NORMAL: return {
            definition: 'A physique considered within a healthy weight range, with a balanced proportion of body fat and muscle.',
            appearance: 'A healthy appearance, with a balanced distribution of body fat and muscle.'
        }
        case Physique.OVERWEIGHT: return {
            definition: 'A physique with excess body fat, exceeding a healthy weight range.',
            appearance: 'A heavier appearance with visible body fat.'
        }
        case Physique.OBESE: return {
            definition: 'A physique with a significantly excessive amount of body fat, well above a healthy weight range.',
            appearance: 'A very heavy appearance with significant body fat.'
        }
    }
}

// ASSUMPTIONS
// Shredded:
//     Men: 30-40% or higher
// Women: 25-35% or higher
// Lean:
//     Men: 25-35%
//     Women: 20-30%
// Normal:
// Men: 20-25%
// Women: 15-20%
// Overweight:
// Men: 15-20%
// Women: 10-15%
// Obese:
// Men: Below 15%
// Women: Below 10%
export const getMuscleMassMultiplierByPhysique = (l: Physique, gender: Gender) => {
    const genderMultiplier = gender === Gender.WOMEN ? 0.66 : gender === Gender.NON_BINARY ? 0.83 : 1
    switch (l) {
        case Physique.SHREDDED: return 0.4 * genderMultiplier
        case Physique.LEAN: return 0.3 * genderMultiplier
        case Physique.NORMAL: return 0.22 * genderMultiplier
        case Physique.OVERWEIGHT: return 0.17 * genderMultiplier
        case Physique.OBESE: return 0.14 * genderMultiplier
    }
}


export enum CalculationFocus {
    EXTREME_FATLOSS = 'EXTREME_FATLOSS',
    FATLOSS = 'FATLOSS',
    MAINTAIN = 'MAINTAIN',
    GAIN = 'GAIN',
}

export const getCalculationFocusMultiplier = (cf: CalculationFocus)=>{
    switch (cf) {
        case CalculationFocus.EXTREME_FATLOSS: return 0.8
        case CalculationFocus.FATLOSS: return 0.9
        case CalculationFocus.MAINTAIN: return 1
        case CalculationFocus.GAIN: return 1.1
    }
}

export const getCalculationFocusHint = (l: CalculationFocus) => {
    switch (l) {
        case CalculationFocus.EXTREME_FATLOSS: return 'Extreme focus on a calorie deficit to lose body fat or shred.'
        case CalculationFocus.FATLOSS: return 'Focus on a calorie deficit to lose body fat.'
        case CalculationFocus.MAINTAIN: return 'Aim for a calorie balance to maintain weight and body composition.'
        case CalculationFocus.GAIN: return 'Aim for a calorie surplus to gain muscle mass.'
    }
}

export enum Gender {
    MEN = 'MEN',
    WOMEN = 'WOMEN',
    NON_BINARY = 'NON_BINARY'
}

export const getCalorieMultiplier = (macroNutrient: MacroNutrients) => {
    switch (macroNutrient) {
        case MacroNutrients.PROTEIN: return 4
        case MacroNutrients.CARBOHYDRATES: return 5
        case MacroNutrients.FATS: return 9
    }
}

export const calculateBMRHarrisBenedict = (
    gender: Gender,
    weight: number, // in kg
    height: number,  // in cms
    age: number // in years
): number => {
    switch (gender) {
        case Gender.MEN: return 88.362 + (13.397 * weight) + (4.799 * height) - (5.677 * age)
        case Gender.WOMEN: return 447.2 + (9.247 * weight) + (3.098 * height) - (4.330 * age)
        case Gender.NON_BINARY:
            return (calculateBMRHarrisBenedict(Gender.MEN, weight, height, age) + calculateBMRHarrisBenedict(Gender.WOMEN, weight, height, age)) / 2
    }
}
export const calculateBMRRevisedHarrisBenedict = (
    gender: Gender,
    weight: number, // in kg
    height: number,  // in cms
    age: number, // in years
    muscleMass: number // in kgs
): number => {
    return calculateBMRHarrisBenedict(gender, weight, height, age) + 0.202 * muscleMass
}

export const calculateBMRMifflinStJeor = (
    gender: Gender,
    weight: number, // in kg
    height: number,  // in cms
    age: number // in years
): number => {
    switch (gender) {
        case Gender.MEN: return (10 * weight) + (6.25 * height) - (5 * age) + 5
        case Gender.WOMEN: return (10 * weight) + (6.25 * height) - (5 * age) - 161
        case Gender.NON_BINARY:
            return (calculateBMRMifflinStJeor(Gender.MEN, weight, height, age) + calculateBMRMifflinStJeor(Gender.WOMEN, weight, height, age)) / 2
    }
}

export enum DietType {
    NORMAL = "NORMAL",
    KETOGENIC = "KETOGENIC",
    HIGH_PROTEIN = "HIGH_PROTEIN",
    MEDITERRANEAN = "MEDITERRANEAN",
    PALEO = "PALEO",
    VEGETARIAN = 'VEGETARIAN'
}

export type MacrosDistribution = {
    [key in keyof typeof MacroNutrients]: number;
}

// ASSUMPTIONS
// Low-Carb Diet (e.g., Ketogenic)
// Protein: 60-75%
// Fat: 20-35%
// Carbohydrates: 5-10%
// High-Protein Diet
// Protein: 40-50%
// Carbohydrates: 30-40%
// Fat: 20-30%
// Mediterranean Diet
// Protein: 20-30%
// Carbohydrates: 40-50%
// Fat: 25-35% (mostly healthy fats)
// Vegetarian/Vegan Diet
// Protein: 10-20% (from plant-based sources)
// Carbohydrates: 50-70%
// Fat: 20-30%
// NORMAL
// Protein: 20-30% of daily calories
// Carbohydrates: 45-65% of daily calories
// Fat: 20-35% of daily calories
// PALEO
// Protein: 20-30% of daily calories
// Carbohydrates: 30-40% of daily calories
// Fat: 30-40% of daily calories
export const getMacrosDistributionPerDietType = (dietType: DietType): MacrosDistribution => {
    switch (dietType) {
        case DietType.NORMAL: return {
            [MacroNutrients.CARBOHYDRATES]: 58,
            [MacroNutrients.FATS]: 25,
            [MacroNutrients.PROTEIN]: 17
        }
        case DietType.PALEO: return {
            [MacroNutrients.CARBOHYDRATES]: 45,
            [MacroNutrients.FATS]: 30,
            [MacroNutrients.PROTEIN]: 25
        }
        case DietType.KETOGENIC: return {
            [MacroNutrients.CARBOHYDRATES]: 5,
            [MacroNutrients.FATS]: 67,
            [MacroNutrients.PROTEIN]: 28
        }
        case DietType.HIGH_PROTEIN: return {
            [MacroNutrients.CARBOHYDRATES]: 38,
            [MacroNutrients.FATS]: 22,
            [MacroNutrients.PROTEIN]: 40
        }
        case DietType.MEDITERRANEAN: return {
            [MacroNutrients.CARBOHYDRATES]: 47,
            [MacroNutrients.FATS]: 30,
            [MacroNutrients.PROTEIN]: 23
        }
        case DietType.VEGETARIAN: return {
            [MacroNutrients.CARBOHYDRATES]: 60,
            [MacroNutrients.FATS]: 25,
            [MacroNutrients.PROTEIN]: 15
        }
    }
}

export const calculateMacros = (
    gender: Gender,
    weight: number, // in kg
    height: number,  // in cms
    age: number, // in years
    physique: Physique,
    dietType: DietType | MacrosDistribution,
    activityLevel: ActivityLevel,
    calculationFocus: CalculationFocus
) => {
    const muscleMassMultiplierByPhysique = getMuscleMassMultiplierByPhysique(physique, gender)
    const baseTargetCalories = calculateBMRRevisedHarrisBenedict(gender, weight,height, age, weight * muscleMassMultiplierByPhysique)
    const activityLevelMultiplier = getActivityLevelMultiplier(activityLevel)
    const targetCalories =  baseTargetCalories * activityLevelMultiplier
    const macrosDistributionPerDietType = typeof dietType === 'string' ? getMacrosDistributionPerDietType(dietType) : dietType
    const macrosDistribution = {
        [MacroNutrients.CARBOHYDRATES]: (targetCalories * macrosDistributionPerDietType[MacroNutrients.CARBOHYDRATES] / 100) / getCalorieMultiplier(MacroNutrients.CARBOHYDRATES),
        [MacroNutrients.FATS]: (targetCalories * macrosDistributionPerDietType[MacroNutrients.FATS] / 100) / getCalorieMultiplier(MacroNutrients.FATS),
        [MacroNutrients.PROTEIN]: (targetCalories * macrosDistributionPerDietType[MacroNutrients.PROTEIN] / 100) / getCalorieMultiplier(MacroNutrients.PROTEIN),
    }
    const targetCaloriesByCalculationFocus = targetCalories * getCalculationFocusMultiplier(calculationFocus)
    const macrosDistributionByCalculationFocus = {
        [MacroNutrients.CARBOHYDRATES]: (targetCaloriesByCalculationFocus * macrosDistributionPerDietType[MacroNutrients.CARBOHYDRATES] / 100) / getCalorieMultiplier(MacroNutrients.CARBOHYDRATES),
        [MacroNutrients.FATS]: (targetCaloriesByCalculationFocus * macrosDistributionPerDietType[MacroNutrients.FATS] / 100) / getCalorieMultiplier(MacroNutrients.FATS),
        [MacroNutrients.PROTEIN]: (targetCaloriesByCalculationFocus * macrosDistributionPerDietType[MacroNutrients.PROTEIN] / 100) / getCalorieMultiplier(MacroNutrients.PROTEIN),
    }

    return {
        baseTargetCalories, // in kcal
        targetCalories, // in kcal
        targetCaloriesByCalculationFocus, // in kcal
        macrosDistribution, // in grams,
        macrosDistributionByCalculationFocus, // in grams
    }
}

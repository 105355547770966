import {
  center,
  Container,
  flex_col,
  graylight,
  LeftSection,
  PageHeading,
  primary,
  RightSection,
  text_xs,
  white,
} from '../../styles/GlobalStyles'
import { styled } from 'styled-components'
import Transition from '../../Transition'
import { FaImage, FaMapMarkedAlt, FaHeading, FaPlus } from 'react-icons/fa'
import { CiText } from 'react-icons/ci'
import { ImEmbed } from 'react-icons/im'
import { ChangeEvent, Fragment, ReactNode, useRef, useState } from 'react'
import { motion, Variants } from 'framer-motion'

const AddPost = () => {
  const [showListIcon, setShowListIcon] = useState(false)
  const [listComponent, setListComponent] = useState<ReactNode[]>([])
  const [openHeading, setOpenHeading] = useState(false)
  const [embed, setEmbed] = useState('')
  const [inputs, setInputs] = useState<{ id: number; value: string }[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const toggleHeading = () => {
    setOpenHeading(!openHeading)
  }
  const handleAddText = (type = 'span') => {
    const newId = inputs.length > 0 ? inputs[inputs.length - 1].id + 1 : 0
    setInputs((prev) => [...prev, { id: newId, value: '' }])
    setListComponent((prev) => [
      ...prev,
      <Heading as={type} key={listComponent.length}>
        <InputEditor
          onChange={(e) => handleInputChange(newId, e.target.value)}
        />
      </Heading>,
    ])
  }
  const handleInputChange = (id: number, newValue: string) => {
    setInputs((prevInputs) =>
      prevInputs.map((input) =>
        input.id === id ? { ...input, value: newValue } : input
      )
    )
    if (newValue.trim() === '') {
      removeInput(id)
    }
  }
  const removeInput = (id: number) => {
    setInputs((prevInputs) => prevInputs.filter((input) => input.id !== id))
  }
  const handleAddEmbed = () => {
    setListComponent((prev) => [
      ...prev,
      <InputEditor
        key={listComponent.length}
        value={embed}
        onChange={(e) => setEmbed(e.target.value)}
        onKeyDown={(e) => handleEnterEmbed(e)}
        placeholder="paste a link from another site and press enter"
      />,
    ])
  }
  const handleEnterEmbed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setListComponent((prev) => [
        ...prev,
        <Embed
          width={'100%'}
          height={'400px'}
          src="https://www.youtube.com/watch?v=IG8Naq7Q2Q8&list=RDIG8Naq7Q2Q8&start_radio=1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          key={listComponent.length}
        />,
      ])
    }
  }

  const listIcon = [
    { icon: CiText, hint: 'add text', onclick: () => handleAddText('span') },
    { icon: FaHeading, hint: 'add heading', onclick: toggleHeading },
    { icon: FaImage, hint: 'add image' },
    { icon: FaMapMarkedAlt, hint: 'add map' },
    { icon: ImEmbed, hint: 'add embed', onclick: handleAddEmbed },
  ]
  const listHeading = [
    { label: 'heading 1', onClick: () => handleAddText('h1') },
    { label: 'heading 2', onClick: () => handleAddText('h2') },
    { label: 'heading 3', onClick: () => handleAddText('h3') },
    { label: 'heading 4', onClick: () => handleAddText('h4') },
    { label: 'heading 5', onClick: () => handleAddText('h5') },
  ]

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const url = e.target?.result as string
        setListComponent((prev) => [
          ...prev,
          <Img src={url} key={listComponent.length} />,
        ])
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <Transition>
      <Wrapper data-color-mode="dark">
        <LeftSection>
          <PageHeading as={'h2'}>create post</PageHeading>
          <Label>title</Label>
          <Input />
          <Label>content</Label>
          <ContentContainer>
            {listComponent.map((com, index) => {
              return <Fragment key={index}>{com}</Fragment>
            })}
            <ListIcon>
              <IconButton
                className={`${showListIcon ? 'rotate' : ''}`}
                onClick={() => setShowListIcon(!showListIcon)}
              >
                <FaPlus size={20} />
              </IconButton>
              <OptionsIcon
                initial={false}
                animate={showListIcon ? 'open' : 'closed'}
                variants={{
                  open: {
                    transition: {
                      duration: 0.7,
                      delayChildren: 0.3,
                      staggerChildren: 0.1,
                    },
                  },
                  closed: {
                    transition: {
                      duration: 0.3,
                    },
                  },
                }}
              >
                {listIcon.map((option, index) => {
                  return (
                    <IconButton
                      key={index}
                      variants={itemVariants}
                      onClick={
                        index === 2
                          ? () => {
                              if (fileInputRef.current)
                                fileInputRef.current.click()
                            }
                          : option.onclick
                      }
                    >
                      <option.icon size={20} />
                      {index === 1 && openHeading && (
                        <ListHeading>
                          {listHeading.map((heading, index) => (
                            <HeadingOptions
                              key={index}
                              onClick={heading.onClick}
                            >
                              {heading.label}
                            </HeadingOptions>
                          ))}
                        </ListHeading>
                      )}
                      {index === 2 && (
                        <input
                          type="file"
                          accept="image/*"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={(e) => {
                            handleImageUpload(e)
                          }}
                        />
                      )}
                      <Hint>{option.hint}</Hint>
                    </IconButton>
                  )
                })}
              </OptionsIcon>
            </ListIcon>
          </ContentContainer>
        </LeftSection>
        <RightSection>
          <PageHeading as={'h2'}>preview content</PageHeading>
          <PreviewContent>###Heading1</PreviewContent>
        </RightSection>
      </Wrapper>
    </Transition>
  )
}
export default AddPost
const itemVariants: Variants = {
  open: {
    opacity: 1,
    scale: 1,
    visibility: 'visible',
    transition: { type: 'spring', stiffness: 300, damping: 24 },
  },
  closed: {
    opacity: 0,
    visibility: 'hidden',
    scale: 0.97,
    transition: { duration: 0.2 },
  },
}
const Wrapper = styled(Container)`
  display: flex;
  gap: 20px;
`
const Label = styled.div``
const Input = styled.input`
  width: 100%;
  padding: 16px 20px;
  caret-color: white;
  color: ${graylight};
  background: rgba(31, 31, 31, 1);
  border: none;
  border-bottom: 1px solid transparent;
  outline: none;
`
const ContentContainer = styled.div`
  gap: 10px;
  padding: 20px 10px;
  background-color: rgb(31, 31, 31);
  ${flex_col}
`
const ListIcon = styled.div`
  display: flex;
  gap: 10px;
`
const Hint = styled.div`
  visibility: hidden;
  width: fit-content;
  background-color: rgb(51, 49, 49);
  color: ${graylight};
  text-align: center;
  padding: 10px;
  opacity: 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 10px);
  transition: opacity 0.5s ease-in-out;
  ${text_xs}
  &::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(31, 31, 31, 1) transparent transparent transparent;
  }
`

const IconButton = styled(motion.div)`
  position: relative;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid ${primary};
  background-color: transparent;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  ${center}
  &.rotate {
    transform: rotate(225deg);
  }
  &:hover ${Hint} {
    visibility: visible;
    opacity: 1;
  }
`
const OptionsIcon = styled(motion.div)`
  display: flex;
  gap: 10px;
`
const PreviewContent = styled.div`
  padding: 20px 10px;
  background-color: rgb(31, 31, 31);
  min-height: 100%;
`
const Heading = styled.div`
  font-size: revert;
`
const ListHeading = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: max-content;
  background-color: grey;
  ${flex_col}
`
const HeadingOptions = styled.div`
  padding: 5px;
  transition: all 0.5s ease-in-out;
  &:hover {
    background-color: ${primary};
  }
`
const Img = styled.img``
const InputEditor = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  caret-color: ${white};
  color: ${white};
`
const Embed = styled.iframe``

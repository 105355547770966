import { AnimatePresence, motion } from 'framer-motion'
import { FaSearch } from 'react-icons/fa'
import { RotatingLines } from 'react-loader-spinner'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  growHover,
  white,
  graylight,
  black,
  text_lg,
  flex_col,
  ralewayFont,
  grayverylight,
  text_sm,
  center,
} from '../../styles/GlobalStyles'
import { useEffect, useRef, useState } from 'react'
import { Article } from '../../models/article'
import { Author } from '../../models/author'
import { useAuthors } from '../../hooks/useIndexedAuthors'
import { useArticles } from '../../hooks/useIndexedPosts'
import { useDebouncedCallback } from 'use-debounce'
import useClickOutside from '../../hooks/handleClickOutside'
import parse from 'html-react-parser'
import { t } from 'i18next'

const Search: React.FC = () => {
  //* START * get author, articles
  const { authors } = useAuthors()
  const { articles } = useArticles()
  const [matchArticles, setMatchArticles] = useState<Article[]>([])
  const [matchAuthors, setMatchAuthors] = useState<Author[]>([])
  //* END *
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [search, setSearch] = useState<string>('')
  const [isFocus, setIsFocus] = useState(false)
  const [openResult, setOpenResult] = useState(false)
  const inputSearch = useRef<HTMLInputElement>(null)
  const refResult = useRef<HTMLDivElement>(null)
  //* START * Handle Search Query
  const handleSearchQuery = useDebouncedCallback((search: string) => {
    const searchKey = search.toLowerCase().trim()
    const matchAuthor: Author[] = Array.from(authors.values()).filter(
      (author) => {
        const authorName = author.name.toLowerCase().trim()
        return authorName.includes(searchKey)
      }
    )
    const matchArticle: Article[] = Array.from(articles.values()).filter(
      (article) => {
        const articleTitle = article.title.toLowerCase().trim()
        return articleTitle.includes(searchKey)
      }
    )
    setMatchArticles(matchArticle)
    setMatchAuthors(matchAuthor)
    if (!search) {
      setMatchArticles([])
      setMatchAuthors([])
    }
  }, 1000)
  useEffect(() => {
    setLoadingSearch(handleSearchQuery.isPending())
  }, [handleSearchQuery.isPending()])
  //* END *
  //* START * toggle input when click icon
  const toggleIcon = () => {
    if (inputSearch.current) {
      setIsFocus(true)
      inputSearch.current.style.visibility = 'visible'
      inputSearch.current.focus()
    }
  }
  //* END *
  const handleOnBlur = () => {
    if (inputSearch.current && inputSearch.current.value === '') {
      setIsFocus(!isFocus)
      inputSearch.current.style.visibility = 'hidden'
      inputSearch.current.blur()
    }
  }
  const showResult = () => {
    setOpenResult(true)
  }
  const hideResult = () => {
    setOpenResult(false)
    handleSearchQuery.flush()
  }
  useClickOutside(refResult, () => {
    if (
      inputSearch.current &&
      !inputSearch.current.contains(event?.target as Node)
    ) {
      hideResult()
    }
  })
  const hightlightKeyword = (string: string) => {
    const regex = new RegExp(`(${search.trim()})`, 'i')
    const match = string.match(regex)
    if (match) {
      const hightlightText = string.replaceAll(
        `${match[0]}`,
        `<span style="font-weight:700">${match[0]}</span>`
      )
      return parse(hightlightText)
    } else {
      return string
    }
  }
  const navigate = useNavigate()
  return (
    <SearchContainer>
      <SearchBox
        ref={inputSearch}
        data-isfocus={isFocus}
        onBlur={handleOnBlur}
        onFocus={() => showResult()}
        value={search}
        onChange={(e) => {
          if (!openResult) showResult()
          const newValue = e.target.value
          setSearch(newValue)
          handleSearchQuery(newValue)
        }}
      />
      <MotionIconSearch data-isfocus={isFocus}>
        <IconSearch onClick={toggleIcon} />
      </MotionIconSearch>
      <AnimatePresence>
        <SearchResult
          layout
          className={`${openResult ? 'show' : 'hidden'} `}
          ref={refResult}
        >
          {loadingSearch ? (
            <LoadingSearchQuery
              animate={{
                opacity: [0, 1],
                transition: {
                  delay: 0.5,
                  duration: 0.3,
                },
              }}
            >
              <RotatingLines
                visible={true}
                width="30"
                strokeColor="rgb(255, 255, 255)"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
              />
            </LoadingSearchQuery>
          ) : (
            <>
              <GridResult>
                <ResultTitle
                  variants={result_title}
                  initial="initial"
                  animate="show"
                >
                  {t('header.search.articles')}
                </ResultTitle>
                {matchArticles.length !== 0 ? (
                  <ListResult>
                    {matchArticles.slice(-3).map((article, index) => (
                      <ResultItem
                        as={motion.div}
                        custom={index}
                        initial="initial"
                        animate="show"
                        variants={results}
                        to={`/post/${article.slug}`}
                        key={index}
                        onClick={() => {
                          navigate(`/post/${article.slug}`)
                          hideResult()
                        }}
                      >
                        <ResultName>
                          {hightlightKeyword(article.title)}
                        </ResultName>
                        <ResultTag>
                          {hightlightKeyword(
                            authors.get(article["author-alias"])?.name ||
                              'Unknown Author'
                          )}
                        </ResultTag>
                      </ResultItem>
                    ))}
                  </ListResult>
                ) : (
                  <NoMatchesText
                    variants={result_title}
                    animate="show"
                    initial="initial"
                  >
                    {t('header.search.no-matches')}
                  </NoMatchesText>
                )}
              </GridResult>
              <GridResult>
                <ResultTitle
                  variants={result_title}
                  animate="show"
                  initial="initial"
                >
                  {t('header.search.authors')}
                </ResultTitle>
                {matchAuthors.length !== 0 ? (
                  <ListResult>
                    {matchAuthors.slice(-3).map((author, index) => (
                      <ResultItem
                        as={motion.div}
                        custom={index}
                        variants={results}
                        initial="initial"
                        animate="show"
                        to={`/author/${author.alias}`}
                        key={index}
                        onClick={() => {
                          navigate(`/author/${author.alias}`)
                          hideResult()
                        }}
                      >
                        <ResultName>
                          {hightlightKeyword(author.name)}
                        </ResultName>
                        <ResultTag>{hightlightKeyword(author.bio)}</ResultTag>
                      </ResultItem>
                    ))}
                  </ListResult>
                ) : (
                  <NoMatchesText
                    variants={result_title}
                    initial="initial"
                    animate="show"
                  >
                    {t('header.search.no-matches')}
                  </NoMatchesText>
                )}
              </GridResult>
            </>
          )}
        </SearchResult>
      </AnimatePresence>
    </SearchContainer>
  )
}

export default Search
const result_title = {
  initial: { x: -20, opacity: 0 },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.5,
    },
  },
}
const results = {
  initial: { x: -20, opacity: 0 },
  show: (i: number) => ({
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.5 + i * 0.1,
      duration: 0.5,
    },
  }),
}
const MotionIconSearch = styled(motion.div)`
  display: flex;
  width: 16px;
  position: absolute;
  right: 5px;
  transition: all 1s;
  ${growHover}
  &[data-isfocus='true'] {
    right: 93%;
    color: ${white};
    position: absolute;
    @media (max-width: 500px) {
      top: 5px;
      width: 16px;
      right: calc(100% - 20px);
      left: unset;
    }
  }
  @media (max-width: 500px) {
    top: -56px;
    right: 50px;
  }
  @media (max-width: 767px) {
    width: 25px;
  }
`
const IconSearch = styled(FaSearch)`
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`
const NavItem = styled.div`
  box-sizing: border-box;
  ${center}
  color: ${graylight};
  padding: 10px 20px;
  user-select: none;
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 10px 6.5px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`
const SearchContainer = styled(NavItem)`
  padding: 0;
  margin-left: auto;
  position: relative;
  max-width: 280px;
  @media (max-width: 500px) {
    position: absolute;
    top: 100%;
    width: calc(100% - 60px);
    max-width: 100%;
  }
`
const SearchBox = styled.input`
  visibility: hidden;
  width: 100%;
  box-sizing: border-box;
  padding: 10.5px 25px;
  color: white;
  background: transparent;
  border: transparent;
  border-bottom: 1px solid transparent;
  outline: none;
  &[data-isfocus='true'] {
    outline: none;
    caret-color: white;
    border-bottom: 1px solid ${graylight};
    @media (max-width: 500px) {
      top: 100%;
    }
  }
  @media (max-width: 768px) {
    padding: 10.5px 30px;
  }
  @media (max-width: 500px) {
    background: rgba(0, 0, 0, 0.04);
  }
`
const SearchResult = styled(motion.div)`
  width: 100%;
  height: auto;
  padding: 20px 0;
  position: absolute;
  z-index: 10;
  row-gap: 30px;
  top: 100%;
  border: 1px solid ${white};
  background-color: ${black};
  visibility: hidden;
  opacity: 0;
  ${text_lg}
  ${flex_col}
  transition: opacity 0.5s ease-in;
  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
  &.show {
    visibility: visible;
    opacity: 1;
  }
`
const GridResult = styled.div`
  ${flex_col}
  row-gap: 10px;
  font-family: ${ralewayFont};
`
const ResultTitle = styled(motion.span)`
  color: ${grayverylight};
  padding: 0 10px;
`
const ListResult = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 10px;
`
const ResultItem = styled(Link)`
  ${flex_col}
  cursor: pointer;
  padding: 2px 10px;
  user-select: none;
  background: transparent;
  transition: background 0.3s ease-in;
  &:hover {
    background: ${grayverylight};
  }
`
const ResultTag = styled.div`
  ${text_sm}
  color: ${graylight};
  line-height: 1.25;
`
const ResultName = styled.div`
  color: ${white};
  line-height: 1.35;
`
const LoadingSearchQuery = styled(motion.div)`
  ${center}
`
const NoMatchesText = styled(motion.span)`
  padding: 0 10px;
`

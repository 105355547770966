import { Link, useParams } from 'react-router-dom'
import {
  Container,
  flex_col,
  graylight,
  grayverylight,
  LeftSection,
  primary,
  ralewayFont,
  text_lg,
  text_sm,
  text_xl,
} from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import { useAuthors } from '../../hooks/useIndexedAuthors'
import { useEffect, useState } from 'react'
import { Author, Socials } from '../../models/author'
import { styled } from 'styled-components'
import Text from '../../components/Text'
import { useArticles } from '../../hooks/useIndexedPosts'
import { Article } from '../../models/article'
import { FaGithub, FaTelegram, FaLinkedin, FaInstagram } from 'react-icons/fa'
import { t } from 'i18next'
import { IconType } from 'react-icons'

type SocialsIconMap = {
  readonly [social: string]: IconType
}

const AuthorPage: React.FC = () => {
  const { alias } = useParams<{ alias: string }>()
  const { authors } = useAuthors()
  const { articles } = useArticles()
  const [article, setArticle] = useState<Article[]>([])
  const [author, setAuthor] = useState<Author | undefined>()
  const socialsIcon: SocialsIconMap = {
    [Socials.instagram]: FaInstagram,
    [Socials.github]: FaGithub,
    [Socials.telegram]: FaTelegram,
    [Socials.linkedin]: FaLinkedin,
  }

  useEffect(() => {
    setAuthor(alias ? authors.get(alias) : undefined)
    const authorArticle: Article[] = Array.from(articles.values()).filter(
      (article) => {
        return article['author-alias'] === alias
      }
    )
    setArticle(authorArticle)
  }, [authors])
  return (
    <Transition>
      <Container>
        <AuthorSection>
          <ProfileSection>
            {author ? (
              <>
                <ProfilePicture src={`/assets/${author.profile_image}`} />
                <ProfileMainDetails>
                  <ProfileName as={'h2'}>{author.name}</ProfileName>
                  <ProfileSlogan as={'h5'}>{author.slogan}</ProfileSlogan>
                  <ProfileActivity>
                    {`since ${author.join_date} | . ${article?.length} posts`}
                  </ProfileActivity>
                </ProfileMainDetails>
                <ProfileSocials>
                  {author?.socials ? (
                    (Object.keys(author.socials) as Socials[]).map(
                      (el, index) => {
                        const socialIcon = socialsIcon[el]
                        return (
                          <SocialLink
                            key={index}
                            href={`${author.socials[el]}`}
                            target="_blank"
                          >
                            {socialIcon && socialIcon({ size: '20px' })}
                          </SocialLink>
                        )
                      }
                    )
                  ) : (
                    <></>
                  )}
                </ProfileSocials>
                <ProfileBio>{author.bio}</ProfileBio>
              </>
            ) : null}
          </ProfileSection>
          <PostSection>
            <PostHeading as={'h5'}>{t('common.latest-posts')}</PostHeading>
            {article ? (
              <PostsList>
                {article.map((post, index) => (
                  <Post to={`/post/${post.slug}`} key={index}>
                    <PostThumbnail src={`${post.thumbnail}`} />
                    <PostDetails>
                      <PostTitle as={'h5'}>{post.title}</PostTitle>
                      <PostContent>{post.description}</PostContent>
                      <PostPublished>{post.publishedDate}</PostPublished>
                    </PostDetails>
                  </Post>
                ))}
              </PostsList>
            ) : null}
          </PostSection>
        </AuthorSection>
      </Container>
    </Transition>
  )
}
export default AuthorPage

const AuthorSection = styled(LeftSection)`
  row-gap: 30px;
`
const ProfileSection = styled.div`
  ${flex_col};
  row-gap: 10px;
`
const ProfilePicture = styled.img`
  width: 60px;
  height: 60px;
  object-fit: fill;
  border-radius: 50%;
`
const ProfileMainDetails = styled.div``
const ProfileName = styled(Text)`
  margin-bottom: 10px;
`
const ProfileSlogan = styled(Text)`
  color: ${graylight};
`
const ProfileActivity = styled(Text)`
  color: ${grayverylight};
  ${text_sm};
  letter-spacing: 0.1em;
  line-height: 2.5;
`
const ProfileSocials = styled.div`
  color: ${graylight};
  display: flex;
  column-gap: 10px;
`
const SocialLink = styled.a`
  ${text_lg};
`
const ProfileBio = styled(Text)`
  font-weight: 100;
  font-size: 16px;
  line-height: 1.4;
  color: ${graylight};
  ${ralewayFont}
`
const PostSection = styled.div`
  ${flex_col};
  row-gap: 30px;
`
const PostHeading = styled(Text)`
  color: ${grayverylight};
  ${text_xl}
`
const PostsList = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 10px;
`
const Post = styled(Link)`
  display: flex;
  padding: 20px;
  column-gap: 20px;
  border: 1px solid ${grayverylight};
  transition: all 0.5s;
  &:hover {
    border-color: ${primary};
  }
`
const PostThumbnail = styled.img`
  width: 140px;
  object-fit: cover;
`
const PostDetails = styled.div`
  ${flex_col};
  row-gap: 10px;
`
const PostTitle = styled(Text)`
  color: ${graylight};
`
const PostContent = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${grayverylight};
  ${ralewayFont}
`
const PostPublished = styled(Text)`
  color: ${grayverylight};
  ${text_lg};
  font-weight: 600;
  ${ralewayFont}
`

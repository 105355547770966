export const WORDS_PER_MINUTE = 230;

export const countWords = (s: string) => {
    s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
    s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
    return s.split(' ').filter(function(str){return str!="";}).length;
    //return s.split(' ').filter(String).length; - this can also be used
}

export const calculateReadingTime = (content: string) => {
    const count = countWords(content)
    return (count / WORDS_PER_MINUTE).toFixed(0)
}

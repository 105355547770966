import { createContext, ReactNode } from 'react'
import { Author } from '../models/author'
import dataAuthors from '../data/dataAuthor.json'

export const AuthorsContext = createContext<AuthorsContextType | undefined>(
  undefined
)
interface AuthorsContextType {
  authors: Map<string, Author>
}
interface AuthorsProviderProps {
  children: ReactNode
}
export const AuthorsProvider: React.FC<AuthorsProviderProps> = ({
  children,
}) => {
  const authors: Map<string, Author> = new Map(Object.entries(dataAuthors))
  return (
    <AuthorsContext.Provider value={{ authors }}>
      {children}
    </AuthorsContext.Provider>
  )
}

import Transition from '../../Transition'
import LifeStyle from '../lifestyle'

const Thoughts: React.FC = () => {
  return (
    <Transition>
      <LifeStyle />
    </Transition>
  )
}
export default Thoughts

import 'styled-components'
import { Colors, Fonts } from './styled'
import { DefaultTheme } from 'styled-components'

export const colors = (darkMode: boolean): Colors => ({
  white: darkMode ? 'white' : 'black',
  black: darkMode ? 'black' : 'white',
  primary: '#2BC713',
  heading: darkMode ? 'white' : 'black',
  grayish: 'rgba(255, 255, 255, 0.8)',
  graylight: 'rgba(255, 255, 255, 0.5)',
  grayverylight: 'rgba(255, 255, 255, 0.3)',
  grayveryverylight: 'rgba(255, 255, 255, 0.1)',
})

export const fonts: Fonts = {
  orbitron: 'Orbitron',
  raleway: 'Raleway',
}

export const theme = (darkMode: boolean): DefaultTheme => {
  return {
    colors: colors(darkMode),
    fonts,
  }
}

import { useEffect, useRef, useState } from 'react'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import { styled } from 'styled-components'
import {
  center,
  graylight,
  ralewayFont,
  text_2xl,
} from '../../styles/GlobalStyles'

export interface PlayerOptions {
  name: string
  src: string
  img: string
}
export const defaultPlayerOptions: PlayerOptions = {
  name: '',
  src: '',
  img: '',
}
interface PlayerProps {
  slug: string
  options?: PlayerOptions
}
export const Player = ({ options, slug }: PlayerProps) => {
  const [currentPlaying, setCurrentPlaying] = useState<number | null>(null) // Quản lý trạng thái bài hiện tại
  const audioRefs = useRef<AudioPlayer | null>(null) // Lưu các ref của các player
  const parsedOptions = { ...defaultPlayerOptions, ...options }
  const src = parsedOptions.src.split(',')
  const listName = parsedOptions.name.split(',')
  const images = parsedOptions.img.split(',')
  useEffect(() => {
    audioRefs?.current?.audio.current?.pause()
  }, [currentPlaying])
  const playlist = src.map((sound) => {
    return `/assets/posts/${slug}/${sound}`
  })
  const listImages = images.map((img) => {
    return `/assets/posts/${slug}/images/${img}`
  })
  return (
    <>
      {playlist.map((song, index) => (
        <PlayerContainer key={index}>
          <HeaderImage src={listImages[index]} />
          <PlayerComponent
            src={song}
            loop={false}
            ref={index === currentPlaying ? null : audioRefs}
            showJumpControls={false}
            customAdditionalControls={[]}
            header={<PlayerHeader name={listName[index]} />}
            volume={0.3}
            onPlay={() => setCurrentPlaying(index)}
            layout="horizontal"
          />
        </PlayerContainer>
      ))}
    </>
  )
}
const PlayerHeader = ({ name }: { name: string }) => {
  return (
    <HeaderContainer>
      <HeaderName>{name}</HeaderName>
    </HeaderContainer>
  )
}
const PlayerContainer = styled.div`
  display: flex;
`
const PlayerComponent = styled(AudioPlayer)`
  width: 70%;
  &.rhap_container {
    background-color: rgb(31, 31, 31);
  }
  .rhap_time {
    color: ${graylight};
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
`
const HeaderContainer = styled.div`
  display: flex;
`
const HeaderImage = styled.img`
  width: 30%;
  height: 100px;
  object-fit: cover;
  margin: 0 !important;
`
const HeaderName = styled.div`
  ${center}
  ${text_2xl}
  ${ralewayFont}
`

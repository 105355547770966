import { styled } from 'styled-components'
import {
  black,
  center,
  flex_col,
  graylight,
  text_sm,
  text_xs,
  white,
} from '../../../styles/GlobalStyles'
import { t } from 'i18next'
import { ButtonArrow } from '../../../components/Button'
import { useEffect, useState } from 'react'
import {
  ActivityLevel,
  CalculationFocus,
  DietType,
  Gender,
  getActivityLevelHint,
  getCalculationFocusHint,
  getPhysiqueHint,
  Physique,
} from '../../../utils/MacrosCalculatorUtils'

interface SectionProps {
  listBtn: object
  activeValue: (
    value: Gender | DietType | CalculationFocus | Physique | ActivityLevel
  ) => void
  label: string
  className?: string
  value: Gender | DietType | CalculationFocus | Physique | ActivityLevel
  type?: string
}
export const BtnToggleSection: React.FC<SectionProps> = ({
  listBtn,
  activeValue,
  value,
  label,
  className,
  type,
}) => {
  const [active, setActive] = useState<number>(0)
  useEffect(() => {
    Object.values(listBtn).map((name, index) =>
      name === value ? setActive(index) : true
    )
  }, [])
  return (
    <BtnSection>
      <Label>{t(label)}</Label>
      <BtnList className={className}>
        {Object.values(listBtn).map((name, index) => (
          <Button
            key={index}
            className={`${index === active ? `active` : ''}`}
            onClick={() => {
              activeValue(name)
              setActive(index)
            }}
            sendingType={false}
          >
            {name.replaceAll('_', ' ')}
            {type && (
              <Hint>
                {type === 'physique'
                  ? getPhysiqueHint(name).definition
                  : type === 'calculationFocus'
                    ? getCalculationFocusHint(name)
                    : getActivityLevelHint(name)}
              </Hint>
            )}
          </Button>
        ))}
      </BtnList>
    </BtnSection>
  )
}

const BtnSection = styled.div`
  row-gap: 10px;
  ${flex_col}
`
const Label = styled.div`
  color: ${graylight};
  ${text_sm}
`
const BtnList = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`
const Hint = styled.div`
  visibility: hidden;
  width: fit-content;
  background-color: rgba(31, 31, 31, 1);
  color: ${graylight};
  text-align: center;
  padding: 10px;
  opacity: 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 10px);
  transition: opacity 0.5s ease-in-out;
  ${text_xs}
  &::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(31, 31, 31, 1) transparent transparent transparent;
  }
`

const Button = styled(ButtonArrow)`
  position: relative;
  width: 170px;
  border-radius: 10px;
  font-weight: 500;
  user-select: none;
  letter-spacing: 0.08em;
  border: none;
  transition: all 0.3s ease-in-out;
  padding: 5px;
  ${center}
  &.active {
    background-color: ${white};
    color: ${black};
  }
  &:hover ${Hint} {
    visibility: visible;
    opacity: 1;
  }
`

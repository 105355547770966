import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { DarkModeProvider } from './providers/DarkModeProvider'
import { ArticleProvider } from './providers/IndexedPostsProvider'
import { AuthorsProvider } from './providers/IndexedAuthorsProvider'
import { ProjectProvider } from './providers/IndexedProjectsProvider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <DarkModeProvider>
      <ArticleProvider>
        <AuthorsProvider>
          <ProjectProvider>
            <App />
          </ProjectProvider>
        </AuthorsProvider>
      </ArticleProvider>
    </DarkModeProvider>
  </React.StrictMode>
)

import { styled } from 'styled-components'
import {
  black,
  center,
  Container,
  flex_col,
  graylight,
  grayverylight,
  HeadingTitle,
  LeftSection,
  PageHeading,
  primary,
  ralewayFont,
  RightSection,
  text_2xl,
  text_lg,
  text_xs,
  UnderLine,
} from '../../../styles/GlobalStyles'
import Transition from '../../../Transition'
import { useEffect, useMemo, useRef, useState } from 'react'
import useClickOutside from '../../../hooks/handleClickOutside'
import { SearchResultFood } from '../../../api/foods'
import { RotatingLines } from 'react-loader-spinner'
import { motion } from 'framer-motion'
import Text from '../../../components/Text'
import { SelectedFood } from '../../../models/food'
import useNutritionalTool from '../../../hooks/useNutritionalTool'
import { useDebouncedCallback } from 'use-debounce'
import DoughnutChart from '../../../components/DoughnutChart'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { FaRegTrashAlt, FaPencilAlt, FaPlus, FaCheck } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import useMealPlans, { PlanProps } from '../../../hooks/useMealPlans'
import useDisabledScroll from '../../../hooks/useDisableScroll'

interface macrosInterface {
  baseTargetCalories: number
  targetCalories: number
  targetCaloriesByCalculationFocus: number
  macrosDistribution: {
    CARBOHYDRATES: number
    FATS: number
    PROTEIN: number
  }
  macrosDistributionByCalculationFocus: {
    CARBOHYDRATES: number
    FATS: number
    PROTEIN: number
  }
}
const NutritionalValue = () => {
  const { setIsScroll } = useDisabledScroll()
  const [foodData, setFoodData] = useState<SearchResultFood[] | undefined>([])
  const [selectedFood, setSelectedFood] = useState<SelectedFood | undefined>(
    undefined
  )
  const [foodInput, setFoodInput] = useState<string>('')
  const [mealInput, setMealInput] = useState<string>('')
  const [openResult, setOpenResult] = useState<boolean>(false)
  const [listNutrition, setListNutrition] = useState<number[]>([])
  const [feedback, setFeedback] = useState<string>('')
  const { isLoading, handleSearchFood, handleSelectedFood, setIsLoading } =
    useNutritionalTool()
  const resultRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [macrosCalculation, setMacrosCalculation] = useState<macrosInterface>()
  const [servingSize, setServingSize] = useState<string>('')
  const [isMissingGoal, setIsMissingGoal] = useState(false)
  const [showFood, setShowFood] = useState(false)
  const [isAddMeal, setIsAddMeal] = useState(false)
  const [editMealInput, setEditMealInput] = useState<string>('')
  const [idEditMeal, setIdEditMeal] = useState<number>()
  const [isEditMeal, setIsEditMeal] = useState<boolean>(false)
  const [planName, setPlanName] = useState<string>('')
  const [selectedPlan, setSelectedPlan] = useState<PlanProps | null>(null)
  const [showAddOwnFood, setShowAddOwnFood] = useState<boolean>(false)
  const [listOwnFood, setListOwnFood] = useState<SelectedFood[]>(() => {
    const ownFoods = localStorage.getItem('own-foods')
    return ownFoods ? JSON.parse(ownFoods) : []
  })
  const [ownFood, setOwnFood] = useState<SelectedFood>({
    id: 1,
    description: '',
    calories: 0,
    protein: 0,
    carbohydrates: 0,
    fat: 0,
    servingSize: 0,
    servingUnit: '',
  })
  const {
    listFood,
    setListFood,
    addFood,
    saveMealPlans,
    deleteFood,
    meals,
    deleteMeal,
    addMeal,
    editMeal,
    listPlan,
    deleteSavedPlan,
  } = useMealPlans()
  const [selectedMeal, setSelectedMeal] = useState<string>(meals[0])
  const navigate = useNavigate()

  const isCalculationFocus = useMemo(
    () =>
      macrosCalculation?.targetCalories !==
      macrosCalculation?.targetCaloriesByCalculationFocus,
    [macrosCalculation]
  )
  useEffect(() => {
    setFeedback('')
    const calculateMacros = localStorage.getItem('calculateMacros')
    if (calculateMacros) {
      setMacrosCalculation(JSON.parse(calculateMacros))
    }
  }, [])
  useClickOutside(resultRef, () => {
    if (inputRef.current && !inputRef.current.contains(event?.target as Node)) {
      setOpenResult(false)
      onSearchFood.flush()
    }
  })

  const onSearchFood = useDebouncedCallback((keyword: string) => {
    handleSearchFood(keyword).then((data) => {
      setFoodData(data)
    })
  }, 1000)
  const onSelectedFood = (id?: string | null, ownFood?: SelectedFood) => {
    if (ownFood) {
      setSelectedFood(ownFood)
      if (ownFood?.servingSize) setServingSize(ownFood?.servingSize?.toString())
      if (ownFood) {
        const carbs = ownFood.carbohydrates ? ownFood.carbohydrates : 0
        const proteins = ownFood.protein ? ownFood.protein : 0
        const fats = ownFood.fat ? ownFood.fat : 0
        const totalCalories = carbs * 4 + proteins * 4 + fats * 9
        setListNutrition([
          ((carbs * 4) / totalCalories) * 100,
          ((proteins * 4) / totalCalories) * 100,
          ((fats * 9) / totalCalories) * 100,
        ])
      }
    } else if (id) {
      handleSelectedFood(id).then((data) => {
        setSelectedFood(data)
        if (data?.servingSize) setServingSize(data?.servingSize?.toString())
        if (data) {
          const carbs = data.carbohydrates ? data.carbohydrates : 0
          const proteins = data.protein ? data.protein : 0
          const fats = data.fat ? data.fat : 0
          const totalCalories = carbs * 4 + proteins * 4 + fats * 9
          setListNutrition([
            ((carbs * 4) / totalCalories) * 100,
            ((proteins * 4) / totalCalories) * 100,
            ((fats * 9) / totalCalories) * 100,
          ])
        }
      })
    }
  }
  const handleAddFood = () => {
    addFood(selectedFood, servingSize, selectedMeal)
    setShowFood(false)
  }
  const handleGetPlan = (plan: PlanProps) => {
    setListFood(plan.data)
    setPlanName(plan.name)
    setSelectedPlan(plan)
  }
  const deleteOwnFood = (index: number) => {
    const currentOwnFoods = [...listOwnFood]
    currentOwnFoods.splice(index, 1)
    setListOwnFood(currentOwnFoods)
  }
  useEffect(() => {
    const totalCalories = listFood.reduce((accumulator, meal) => {
      return accumulator + (meal.calories ? meal.calories : 1)
    }, 0)
    if (macrosCalculation) {
      const targetCalories = isCalculationFocus
        ? macrosCalculation.targetCaloriesByCalculationFocus.toFixed(2)
        : macrosCalculation.targetCalories.toFixed(2)
      setIsMissingGoal(totalCalories < parseFloat(targetCalories))
    }
  }, [listFood])
  useEffect(() => {
    localStorage.setItem('own-foods', JSON.stringify(listOwnFood))
  }, [listOwnFood])
  useEffect(() => {
    setIsLoading(onSearchFood.isPending())
  }, [onSearchFood.isPending()])

  return (
    <Transition>
      <NutritionContainer>
        <LeftSection>
          <HeadingTitle>{t('tools.nutritional-value.title.1')}</HeadingTitle>
          <SearchFood>
            <Input
              value={foodInput}
              ref={inputRef}
              placeholder={`${t('tools.nutritional-value.common.placeholder.foodName')}`}
              onFocus={() => setOpenResult(true)}
              onChange={(e) => {
                setFoodInput(e.target.value)
                const keyword = e.target.value
                onSearchFood(keyword)
              }}
            />
            <SearchList ref={resultRef} className={`${openResult && 'open'}`}>
              {isLoading ? (
                <LoadingSearchQuery
                  animate={{
                    opacity: [0, 1],
                    transition: {
                      delay: 0.5,
                      duration: 0.3,
                    },
                  }}
                >
                  <RotatingLines
                    visible={true}
                    width="30"
                    strokeColor="rgb(255, 255, 255)"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                  />
                </LoadingSearchQuery>
              ) : foodData && foodData.length != 0 ? (
                <>
                  <Label>{t('tools.nutritional-value.search.1')}</Label>
                  {listOwnFood &&
                    listOwnFood.length !== 0 &&
                    listOwnFood
                      .filter((food) => food.description.includes(foodInput)) // Lọc các phần tử phù hợp
                      .map((food, index) => (
                        <FoodResult
                          key={index}
                          onClick={() => {
                            setOpenResult(!openResult)
                            setShowFood(true)
                            setShowAddOwnFood(false)
                            onSelectedFood('', food)
                          }}
                        >
                          <FoodDescription>{food.description}</FoodDescription>
                        </FoodResult>
                      ))}
                  <UnderLine />
                  <Label>{t('tools.nutritional-value.search.2')}</Label>
                  {foodData.slice(0, 10).map((food, index) => (
                    <FoodResult
                      key={index}
                      onClick={() => {
                        setOpenResult(!openResult)
                        setShowFood(true)
                        onSelectedFood(food.fdcId.toString())
                        setShowAddOwnFood(false)
                      }}
                    >
                      <FoodDescription>{food.description}</FoodDescription>
                      {food.brandOwner && (
                        <FoodBrandOwner>{food.brandOwner}</FoodBrandOwner>
                      )}
                    </FoodResult>
                  ))}
                  <Button
                    $width="250px"
                    onClick={() => {
                      setShowFood(true)
                      setShowAddOwnFood(true)
                      setIsScroll(false)
                    }}
                  >
                    {t('tools.nutritional-value.common.button.add-own-food')}
                  </Button>
                </>
              ) : (
                <>
                  <Text>{'common.no-matches'}</Text>
                  <Button
                    $width="250px"
                    onClick={() => {
                      setShowFood(true)
                      setShowAddOwnFood(true)
                      setIsScroll(false)
                    }}
                  >
                    {t('tools.nutritional-value.common.button.add-own-food')}
                  </Button>
                </>
              )}
            </SearchList>
          </SearchFood>
          <HeadingTitle>{t('tools.nutritional-value.title.2')}</HeadingTitle>
          <CompareSection>
            <MealPlans>
              <Label>
                {t('tools.nutritional-value.target-calories.mealplans.label')}
                {':'}
              </Label>
              <TotalNutrition>
                <TotalCalories $color={`${isMissingGoal ? 'red' : ''} `}>
                  calories:{' '}
                  {listFood.reduce((accumulator, meal) => {
                    return accumulator + (meal.calories ? meal.calories : 0)
                  }, 0)}
                </TotalCalories>
                <TotalValue>
                  carbs:{' '}
                  {listFood.reduce((accumulator, meal) => {
                    return (
                      accumulator +
                      (meal.carbohydrates ? meal.carbohydrates : 0)
                    )
                  }, 0)}
                </TotalValue>
                <TotalValue>
                  proteins:{' '}
                  {listFood.reduce((accumulator, meal) => {
                    return accumulator + (meal.protein ? meal.protein : 0)
                  }, 0)}
                </TotalValue>
                <TotalValue>
                  fats:{' '}
                  {listFood.reduce((accumulator, meal) => {
                    return accumulator + (meal.fat ? meal.fat : 0)
                  }, 0)}
                </TotalValue>
              </TotalNutrition>
              <ActionSavePlan>
                <Input
                  $width="150px"
                  value={planName}
                  onChange={(e) => setPlanName(e.target.value)}
                  placeholder="plan's name"
                />
                <Button
                  onClick={() => {
                    saveMealPlans(planName, selectedPlan)
                    setSelectedPlan(null)
                  }}
                >
                  {t(
                    'tools.nutritional-value.target-calories.mealplans.save-plans'
                  )}
                </Button>
              </ActionSavePlan>
            </MealPlans>
            <DailyTarget>
              <Label>
                {t('tools.nutritional-value.target-calories.goal.label')}:{' '}
              </Label>
              {macrosCalculation && (
                <>
                  <TargetValue>
                    {t('tools.nutritional-value.target-calories.goal.calories')}
                    {': '}
                    {isCalculationFocus
                      ? macrosCalculation.targetCaloriesByCalculationFocus
                      : macrosCalculation.targetCalories.toFixed(2)}
                  </TargetValue>
                  <TargetValue>
                    {t('tools.nutritional-value.target-calories.goal.proteins')}
                    {': '}
                    {isCalculationFocus
                      ? macrosCalculation.macrosDistributionByCalculationFocus
                          .PROTEIN
                      : macrosCalculation.macrosDistribution.PROTEIN.toFixed(2)}
                  </TargetValue>
                  <TargetValue>
                    {t('tools.nutritional-value.target-calories.goal.carbs')}
                    {': '}
                    {isCalculationFocus
                      ? macrosCalculation.macrosDistributionByCalculationFocus
                          .CARBOHYDRATES
                      : macrosCalculation.macrosDistribution.CARBOHYDRATES.toFixed(
                          2
                        )}
                  </TargetValue>
                  <TargetValue>
                    {t('tools.nutritional-value.target-calories.goal.fats')}
                    {': '}
                    {isCalculationFocus
                      ? macrosCalculation.macrosDistributionByCalculationFocus
                          .FATS
                      : macrosCalculation.macrosDistribution.FATS.toFixed(2)}
                  </TargetValue>
                </>
              )}
              <Button
                onClick={() => {
                  navigate('/tools/macros-calculator')
                }}
              >
                {macrosCalculation
                  ? t('tools.nutritional-value.target-calories.goal.modify')
                  : t('tools.nutritional-value.target-calories.goal.setting')}
              </Button>
            </DailyTarget>
          </CompareSection>
          <UnderLine />
          <MealsSection>
            <MealHeading>
              <span style={{ fontSize: '30px' }}>
                {t('tools.nutritional-value.title.3')}
              </span>
              {isAddMeal ? (
                <>
                  <Input
                    value={mealInput}
                    onChange={(e) => setMealInput(e.target.value)}
                    $width={'200px'}
                    $padding="10px"
                  />
                  <ActionButton
                    $bgColor="green"
                    onClick={() => {
                      setIsAddMeal(!isAddMeal)
                      if (mealInput) {
                        addMeal(mealInput)
                        setMealInput('')
                      }
                    }}
                  >
                    <FaCheck size={16} />
                  </ActionButton>
                  <ActionButton
                    $bgColor="red"
                    onClick={() => setIsAddMeal(false)}
                  >
                    <IoClose />
                  </ActionButton>
                </>
              ) : (
                <ActionButton
                  $bgColor="green"
                  onClick={() => {
                    setIsAddMeal(!isAddMeal)
                  }}
                >
                  <FaPlus size={16} />
                </ActionButton>
              )}
            </MealHeading>
            <MealsContainer>
              {meals.map((meal, index) => (
                <Meal key={index}>
                  {isEditMeal && idEditMeal === index ? (
                    <MealAction>
                      <Input
                        $width={'200px'}
                        $padding="5px"
                        value={editMealInput}
                        onChange={(e) => setEditMealInput(e.target.value)}
                      />
                      <ActionButton
                        $bgColor="green"
                        onClick={() => {
                          editMeal(index, editMealInput)
                          setIsEditMeal(false)
                        }}
                      >
                        <FaCheck />
                      </ActionButton>
                      <ActionButton
                        $bgColor="red"
                        onClick={() => setIsEditMeal(false)}
                      >
                        <IoClose />
                      </ActionButton>
                    </MealAction>
                  ) : (
                    <MealAction>
                      <Label>{meal}</Label>
                      <ActionButton
                        $bgColor="green"
                        onClick={() => {
                          setIdEditMeal(index)
                          setIsEditMeal(true)
                          setEditMealInput(meal)
                        }}
                      >
                        <FaPencilAlt />
                      </ActionButton>
                      <ActionButton
                        $bgColor="red"
                        onClick={() => deleteMeal(index)}
                      >
                        <FaRegTrashAlt />
                      </ActionButton>
                    </MealAction>
                  )}
                  {listFood.map((food, index1) => {
                    if (food.meal === meal) {
                      return (
                        <Food key={index1}>
                          <FoodInfo>
                            <FoodDes>
                              {food.description} |{' '}
                              <PrimaryText>
                                {food.calories} calories
                              </PrimaryText>{' '}
                              in{' '}
                              <PrimaryText>
                                {food.servingSize} {food.servingUnit}
                              </PrimaryText>
                            </FoodDes>
                            <FoodNutrition>
                              proteins:{' '}
                              <PrimaryText>{food.protein}g </PrimaryText>| carb:
                              <PrimaryText>{food.carbohydrates}g</PrimaryText> |
                              fats: <PrimaryText>{food.fat}g</PrimaryText>
                            </FoodNutrition>
                          </FoodInfo>
                          <FoodAction>
                            <ActionButton
                              $bgColor="red"
                              onClick={() => deleteFood(index1)}
                            >
                              <FaRegTrashAlt />
                            </ActionButton>
                          </FoodAction>
                        </Food>
                      )
                    }
                  })}
                </Meal>
              ))}
            </MealsContainer>
          </MealsSection>
          <FeedBack>{feedback}</FeedBack>
        </LeftSection>
        <RightSection>
          <HeadingTitle>{t('tools.nutritional-value.title.4')}</HeadingTitle>
          <SavedPlans>
            {listPlan.map((plan, index) => (
              <Plan key={index}>
                <PlanDetails>
                  <PlanName>{plan.name || ' '}</PlanName>
                  <PlanDate>{plan.date}</PlanDate>
                </PlanDetails>
                <PlanActions>
                  <ActionButton
                    $bgColor="green"
                    onClick={() => handleGetPlan(plan)}
                  >
                    <FaPencilAlt />
                  </ActionButton>
                  <ActionButton
                    $bgColor="red"
                    onClick={() => {
                      deleteSavedPlan(index)
                    }}
                  >
                    <FaRegTrashAlt />
                  </ActionButton>
                </PlanActions>
              </Plan>
            ))}
          </SavedPlans>
        </RightSection>
      </NutritionContainer>
      <FoodInfomation className={`${showFood ? 'show' : ''}`}>
        {!showAddOwnFood ? (
          <Details>
            <CloseButton onClick={() => setShowFood(false)}>
              <IoClose />
            </CloseButton>
            <FoodName>
              <Label>
                {t('tools.nutritional-value.nutrition-info.description')}
              </Label>
              {selectedFood ? (
                <span>{`${selectedFood.description} | ${selectedFood.calories} calories in ${selectedFood.servingSize}  grams`}</span>
              ) : null}
            </FoodName>
            <UnderLine />
            <Label>
              {t('tools.nutritional-value.nutrition-info.composition')}
            </Label>
            {selectedFood ? (
              <Composition>
                <CompositionValue>
                  <DoughnutChart
                    id={`chart${selectedFood.id}`}
                    values={listNutrition}
                    label={['carbs', 'protein', 'fats']}
                  />
                </CompositionValue>
              </Composition>
            ) : null}
            <UnderLine />
            <Label>
              {t('tools.nutritional-value.nutrition-info.nutritional.label')}
            </Label>
            {selectedFood ? (
              <NutritionalInfomation>
                <NutritionValue>
                  <NutritionResult $bgColor="#d9f8fa">
                    {selectedFood.calories}{' '}
                    {t('tools.nutritional-value.common.unit.kcal')}
                  </NutritionResult>
                  <NutritionLabel>
                    {t('tools.nutritional-value.nutrition-info.nutritional.1')}
                  </NutritionLabel>
                </NutritionValue>
                <NutritionValue>
                  <NutritionResult $bgColor="#f8f0bf">
                    {selectedFood.carbohydrates}
                    {t('tools.nutritional-value.common.unit.g')}
                  </NutritionResult>
                  <NutritionLabel>
                    {t('tools.nutritional-value.nutrition-info.nutritional.2')}
                  </NutritionLabel>
                </NutritionValue>
                <NutritionValue>
                  <NutritionResult $bgColor="#fddabe">
                    {selectedFood.protein}{' '}
                    {t('tools.nutritional-value.common.unit.g')}
                  </NutritionResult>
                  <NutritionLabel>
                    {t('tools.nutritional-value.nutrition-info.nutritional.3')}
                  </NutritionLabel>
                </NutritionValue>
                <NutritionValue>
                  <NutritionResult $bgColor="#e8fdda">
                    {selectedFood.fat}
                    {t('tools.nutritional-value.common.unit.g')}
                  </NutritionResult>
                  <NutritionLabel>
                    {t('tools.nutritional-value.nutrition-info.nutritional.4')}
                  </NutritionLabel>
                </NutritionValue>
              </NutritionalInfomation>
            ) : null}
            <UnderLine />
            <AddToPlans>
              <Input
                $width="200px"
                value={servingSize}
                placeholder={`${t('tools.nutritional-value.common.placeholder.servingSize')}`}
                onChange={(e) => {
                  setServingSize(e.target.value)
                }}
              />
              <MealOptions
                onChange={(e) => {
                  setSelectedMeal(e.target.value)
                  console.log(selectedMeal)
                }}
              >
                {meals.map((option) => (
                  <Options key={option} value={option}>
                    {option}
                  </Options>
                ))}
              </MealOptions>
              <Button onClick={handleAddFood}>
                {t(
                  'tools.nutritional-value.nutrition-info.add-to-plans.button'
                )}
              </Button>
            </AddToPlans>
          </Details>
        ) : (
          <Details>
            <CloseButton
              onClick={() => {
                setIsScroll(true)
                setShowFood(false)
              }}
            >
              <IoClose />
            </CloseButton>
            <PageHeading>
              {t('tools.nutritional-value.add-own-food.label')}
            </PageHeading>
            <Input
              $padding="10px"
              placeholder={t(
                'tools.nutritional-value.add-own-food.place-holder.name'
              )}
              value={ownFood.description}
              onChange={(e) =>
                setOwnFood({ ...ownFood, description: e.target.value })
              }
            />
            <OwnFoodInfo>
              <Input
                $padding="10px"
                $width="100px"
                type="number"
                placeholder={t(
                  'tools.nutritional-value.add-own-food.place-holder.calories'
                )}
                value={ownFood.calories}
                onChange={(e) =>
                  setOwnFood({
                    ...ownFood,
                    calories: parseFloat(e.target.value),
                  })
                }
              />
              in
              <Input
                $padding="10px"
                $width="100px"
                type="number"
                placeholder={t(
                  'tools.nutritional-value.add-own-food.place-holder.size'
                )}
                value={ownFood.servingSize}
                onChange={(e) =>
                  setOwnFood({
                    ...ownFood,
                    servingSize: parseFloat(e.target.value),
                  })
                }
              />
              <Input
                $padding="10px"
                $width="100px"
                placeholder={t(
                  'tools.nutritional-value.add-own-food.place-holder.unit'
                )}
                value={ownFood.servingUnit}
                onChange={(e) =>
                  e.target.value.trim() &&
                  setOwnFood({ ...ownFood, servingUnit: e.target.value })
                }
              />
            </OwnFoodInfo>

            <Input
              $padding="10px"
              placeholder={t(
                'tools.nutritional-value.add-own-food.place-holder.carbs'
              )}
              type="number"
              value={ownFood.carbohydrates}
              onChange={(e) =>
                setOwnFood({
                  ...ownFood,
                  carbohydrates: parseFloat(e.target.value),
                })
              }
            />
            <Input
              $padding="10px"
              placeholder={t(
                'tools.nutritional-value.add-own-food.place-holder.proteins'
              )}
              type="number"
              value={ownFood.protein}
              onChange={(e) =>
                setOwnFood({ ...ownFood, protein: parseFloat(e.target.value) })
              }
            />
            <Input
              $padding="10px"
              placeholder={t(
                'tools.nutritional-value.add-own-food.place-holder.fats'
              )}
              type="number"
              value={ownFood.fat}
              onChange={(e) => {
                setOwnFood({ ...ownFood, fat: parseFloat(e.target.value) })
                console.log(ownFood)
                console.log(listOwnFood)
              }}
            />
            <Button
              onClick={() => {
                setListOwnFood((prev) => [...prev, ownFood])
                setOwnFood({
                  id: 1,
                  description: '',
                  calories: 0,
                  protein: 0,
                  carbohydrates: 0,
                  fat: 0,
                  servingSize: 0,
                  servingUnit: '',
                })
              }}
            >
              {t('tools.nutritional-value.add-own-food.button.add')}
            </Button>
            <Label>
              {t('tools.nutritional-value.add-own-food.list-foods.label')}
            </Label>
            <ListOwnFoods>
              {listOwnFood.map((food, index) => (
                <>
                  <Food key={index}>
                    <FoodInfo>
                      <FoodDes>
                        {food.description} |{' '}
                        <PrimaryText>
                          {food.calories}{' '}
                          {t(
                            'tools.nutritional-value.add-own-food.food-info.calories.1'
                          )}
                        </PrimaryText>{' '}
                        {t(
                          'tools.nutritional-value.add-own-food.food-info.calories.2'
                        )}{' '}
                        <PrimaryText>
                          {food.servingSize} {food.servingUnit}
                        </PrimaryText>
                      </FoodDes>
                      <FoodNutrition>
                        {t(
                          'tools.nutritional-value.add-own-food.food-info.proteins'
                        )}
                        : <PrimaryText>{food.protein}g </PrimaryText> |{' '}
                        {t(
                          'tools.nutritional-value.add-own-food.food-info.carb'
                        )}
                        : <PrimaryText>{food.carbohydrates}g</PrimaryText> |{' '}
                        {t(
                          'tools.nutritional-value.add-own-food.food-info.fats'
                        )}
                        : <PrimaryText>{food.fat}g</PrimaryText>
                      </FoodNutrition>
                    </FoodInfo>
                    <FoodAction>
                      <ActionButton
                        $bgColor="red"
                        onClick={() => {
                          deleteOwnFood(index)
                        }}
                      >
                        <FaRegTrashAlt />
                      </ActionButton>
                    </FoodAction>
                  </Food>
                </>
              ))}
            </ListOwnFoods>
          </Details>
        )}
      </FoodInfomation>
    </Transition>
  )
}
export default NutritionalValue

const PrimaryText = styled.span`
  color: ${primary};
`
const NutritionContainer = styled(Container)`
  display: flex;
  column-gap: 2%;
`
const SearchFood = styled.div`
  position: relative;
`
const SearchList = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  padding: 10px;
  width: 100%;
  z-index: 10;
  opacity: 0;
  background-color: rgba(31, 31, 31, 1);
  visibility: hidden;
  transition: all 0.5s ease-in-out;
  ${flex_col}
  &.open {
    opacity: 1;
    visibility: visible;
  }
`
const LoadingSearchQuery = styled(motion.div)`
  ${center}
`
const FoodResult = styled.div`
  padding: 20px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  ${flex_col}
  &:hover {
    background-color: ${grayverylight};
  }
`
const Input = styled.input<{ $width?: string; $padding?: string }>`
  width: ${(props) => (props.$width ? props.$width : '100%')};
  position: relative;
  padding: ${(props) => (props.$padding ? props.$padding : '16px 20px')};
  caret-color: white;
  color: ${graylight};
  background: rgba(31, 31, 31, 1);
  border: none;
  border-bottom: 1px solid transparent;
  outline: none;
`
const CompareSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`
const FoodInfomation = styled.div`
  position: fixed;
  top: 0;
  padding: 100px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  transition: all 0.5s ease-in-out;
  &.show {
    opacity: 1;
    visibility: visible;
  }
`
const Details = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
  height: 100%;
  padding: 30px 20px;
  background-color: #2d2d2d;
  gap: 10px;
  ${flex_col}
`
const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  background-color: ${grayverylight};
  ${center}
  &:hover {
    background-color: ${primary};
  }
`
const FoodName = styled.span`
  row-gap: 10px;
  ${text_2xl}
  ${flex_col}
`
const Label = styled.span`
  color: ${graylight};
  ${text_lg}
`
const FoodDescription = styled.span``
const FoodBrandOwner = styled.span`
  color: ${graylight};
  ${text_xs};
`
const Composition = styled.div`
  ${center}
`
const CompositionValue = styled.div``

const NutritionalInfomation = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  justify-items: center;
  margin-top: 10px;
`
const NutritionValue = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 20px;
  row-gap: 5px;
  ${flex_col}
`
const NutritionResult = styled.div<{ $bgColor?: string }>`
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  background: ${(props) => (props.$bgColor ? props.$bgColor : 'transparent')};
  color: ${black};
`
const NutritionLabel = styled.div`
  color: ${graylight};
`
const DailyTarget = styled.div`
  gap: 10px;
  ${flex_col}
  ${ralewayFont}
`
const TargetValue = styled.div``
const FeedBack = styled.div``
const MealPlans = styled.div`
  gap: 10px;
  ${flex_col}
  ${ralewayFont}
`

const Food = styled.div`
  display: flex;
`
const FoodDes = styled.div``
const FoodNutrition = styled.div``
const AddToPlans = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`
const MealOptions = styled.select`
  outline: none;
`
const MealAction = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const Options = styled.option``
const Button = styled.div<{ $width?: string }>`
  width: ${(props) => (props.$width ? props.$width : '150px')};
  background-color: ${graylight};
  transition: all 0.5s ease-in-out;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  ${center}
  &:hover {
    background-color: ${primary};
  }
`
const FoodInfo = styled.div`
  width: 100%;
`
const FoodAction = styled.div`
  display: flex;
  gap: 5px;
`

const ActionButton = styled.div<{ $bgColor?: string }>`
  background-color: ${(props) =>
    props.$bgColor ? props.$bgColor : 'transparent'};
  padding: 10px;
  border-radius: 10px;
  opacity: 0.8;
  transition: opacity 0.5s;
  cursor: pointer;
  ${center}
  &:hover {
    opacity: 1;
  }
`
const ActionSavePlan = styled.div`
  display: flex;
  gap: 10px;
`
const TotalNutrition = styled.div`
  gap: 10px;
  ${flex_col}
`
const TotalCalories = styled.div<{ $color?: string }>`
  color: ${(props) => (props.$color ? props.$color : 'inherit')};
`
const TotalValue = styled.div``
const MealsSection = styled.div``
const Meal = styled.div`
  padding: 20px 20px 20px 0;
  gap: 10px;
  ${flex_col}
`
const MealsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 10px;
  column-gap: 20px;
  ${Meal}:nth-child(odd) {
    border-right: 1px solid ${graylight};
  }
`

const MealHeading = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`
const SavedPlans = styled.div`
  gap: 10px;
  ${flex_col}
`
const Plan = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 10px;
  background-color: ${graylight};
  user-select: none;
`
const PlanName = styled.div``
const PlanDate = styled.div``
const PlanDetails = styled.div`
  width: 100%;
`
const PlanActions = styled.div`
  display: flex;
  gap: 10px;
`
const OwnFoodInfo = styled.div`
  ${center}
  gap: 10px;
`
const ListOwnFoods = styled.div`
  ${flex_col}
  overflow: auto;
  gap: 15px;
`

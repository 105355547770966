import { Container, LeftSection } from '../../styles/GlobalStyles'
import Transition from '../../Transition'

const Subcategory: React.FC = () => {
  return (
    <Transition>
      <Container>
        <LeftSection>
          subcategory page
          {/* <ProfileSection>
            <ProfileMainDetails>
              <ProfileName as={'h2'}>subcategory Name</ProfileName>
              <ProfileSlogan as={'h5'}>short description</ProfileSlogan>
              <ProfileActivity>Created date, posts</ProfileActivity>
            </ProfileMainDetails>
            <ProfileBio>description</ProfileBio>
          </ProfileSection>
          <PostSection>
            <PostHeading as={'h5'}>lastest posts</PostHeading>
            {articles ? (
              <PostsList>
                {Array.from(articles.values()).map((post, index) => (
                  <Post to={`/post/${post.slug}`} key={index}>
                    <PostThumbnail src={postThumbnail} />
                    <PostDetails>
                      <PostTitle as={'h5'}>{post.title}</PostTitle>
                      <PostContent>{post.content}</PostContent>
                      <PostPublished>{post.publishedDate}</PostPublished>
                    </PostDetails>
                  </Post>
                ))}
              </PostsList>
            ) : null}
          </PostSection> */}
        </LeftSection>
      </Container>
    </Transition>
  )
}
export default Subcategory

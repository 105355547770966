import { Link } from 'react-router-dom'
import { Container, primary } from '../../styles/GlobalStyles'
import { styled } from 'styled-components'
import { t } from 'i18next'

const NotFoundPage: React.FC = () => {
  return (
    <Container>
      <h1>{t('error.title')}</h1>
      <p>{t('error.message')}</p>
      <BackLink to="/">{t('error.link')}</BackLink>
    </Container>
  )
}

export default NotFoundPage

const BackLink = styled(Link)`
  color: ${primary};
`

import { Swiper, SwiperSlide } from 'swiper/react'
import ArticleCard from '../ArticleCard'
import { styled } from 'styled-components'
import { FreeMode } from 'swiper/modules'
import { center, text_sm } from '../../styles/GlobalStyles'
import { Article } from '../../models/article'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import 'swiper/css'
import { useState } from 'react'

interface CarouselProps {
  data: Map<string, Article>
  slidesPerView?: number
  breakpoints?: {
    [key: number]: {
      slidesPerView: number
      spaceBetween: number
    }
  }
  setHover?: (isHover: boolean) => void
}
const CarouselPosts: React.FC<CarouselProps> = ({
  data,
  slidesPerView,
  breakpoints,
  setHover,
}) => {
  const [isSwiping, setIsSwiping] = useState(false)
  return (
    <SwiperContainer
      slidesPerView={slidesPerView}
      spaceBetween={20}
      breakpoints={breakpoints}
      freeMode={true}
      modules={[FreeMode]}
      touchRatio={0.7}
      grabCursor={true}
      className="mySwiper"
      onTouchMove={() => {
        setIsSwiping(true)
      }}
      onTransitionStart={() => {
        setIsSwiping(true)
      }}
      onTransitionEnd={() => {
        setIsSwiping(false)
      }}
      onTouchEnd={() => {
        setIsSwiping(false)
      }}
    >
      {Array.from(data.values()).map((item, index) => (
        <SwiperItem
          onMouseEnter={() => setHover && setHover(true)}
          onMouseLeave={() => setHover && setHover(false)}
          key={index}
          className={`${isSwiping ? 'disable' : null}`}
        >
          <ArticleCard key={item.slug} article={item}></ArticleCard>
        </SwiperItem>
      ))}
    </SwiperContainer>
  )
}
const SwiperContainer = styled(Swiper)`
  width: 100%;
  @media (max-width: 767px) {
    margin-left: -20px;
    padding: 0;
  }
`
const SwiperItem = styled(SwiperSlide)`
  ${text_sm};
  height: auto;
  &.disable {
    pointer-events: none;
  }
  @media (min-width: 767px) {
    ${center};
  }
`
export default CarouselPosts

import Markdown from 'react-markdown'
import {
  Container,
  flex_col,
  graylight,
  grayverylight,
  LeftSection,
  UnderLine,
  white,
} from '../../styles/GlobalStyles'
import Transition from '../../Transition'
import { useProjects } from '../../hooks/useIndexedProjects'
import { Project } from '../../models/project'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Text from '../../components/Text'
import { styled } from 'styled-components'

const ProjectMain: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()
  const { projects } = useProjects()
  const [project, setProject] = useState<Project | undefined>()
  useEffect(() => {
    setProject(slug ? projects.get(slug) : undefined)
  }, [slug, projects])
  return (
    <Transition>
      <Container>
        {project ? (
          <LeftSection>
            <ProjectHeading>
              <ProjectTitle as={'h2'}>{project.title}</ProjectTitle>
              <ProjectCategory>{project.categories.join(', ')}</ProjectCategory>
              <ProjectTags>{project.tags.join(', ')}</ProjectTags>
            </ProjectHeading>
            <UnderLine />
            <Markdown>{project.content}</Markdown>
            <UnderLine />
          </LeftSection>
        ) : (
          <Text>{'post.message.not-found'}</Text>
        )}
      </Container>
    </Transition>
  )
}
export default ProjectMain
const ProjectHeading = styled.div`
  ${flex_col}
`
const ProjectTitle = styled(Text)`
  color: ${white};
  line-height: 1.4;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 4px;
  margin-bottom: 10px;
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
  }
`
const ProjectCategory = styled(Text)`
  color: ${graylight};
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: 5px;
  margin-bottom: 10px;
`
const ProjectTags = styled(Text)`
  color: ${grayverylight};
  line-height: 1.5;
  margin-bottom: 20px;
`

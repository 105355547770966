import {Configuration, FDCApi} from "../api/foods";

export const useFoodsApi = () => {
    if (!process.env.REACT_APP_FOODS_API_KEY)
        throw new Error('REACT_APP_FOODS_API_KEY must exist to use this hook')

    return new FDCApi(new Configuration({
        apiKey: process.env.REACT_APP_FOODS_API_KEY,
        basePath: 'https://api.nal.usda.gov/fdc'
    }));
}
